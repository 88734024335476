import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { toast } from 'react-toastify';
import * as $ from 'jquery';
import AdminService from '../services/api';
import ReactInputVerificationCode from "react-input-verification-code";
import apiService from '../services/api';
import Str from '../constants/string';
import Storages from '../constants/storages';
import ReactLoading from 'react-loading';
import { HiddenMenu } from '../Components/Location/HiddenMenu';
import { authenticate } from '../context/socket';
import VersionComponent from '../Version'
import Connection from '../Components/User/Connection';
import { useSelector } from 'react-redux';
import tokenService from '../services/tokenService';

const FirstPage = (props) => {
  const { history } = props;
  const [type, setType] = useState("");
  const [data, setData] = useState([])
  const [showCompanies, setShowCompanies] = useState(false)
  const [companies, setCompanies] = useState([])
  const [company, setCompany] = useState("")
  const [locations, setLocations] = useState([])
  const [location, setLocation] = useState("")
  const [showLocationNames, setShowLocationNames] = useState(false)
  const [buttonText, setButtonText] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [sixCode, setSixCode] = useState("")
  const [installFlag, setInstallFlag] = useState(false)
  const [countNum, setCountNum] = useState(5);
  const connections = useSelector((state) => state.connections)

  useEffect(() => {
    if(connections.internet && connections.server) {
      setIsLoading(true);
      init()
    }
  }, [connections])

  async function init() {
    try{
      if(!window.electron && process.env.NODE_ENV !== "development") {
        return history.replace("/login")
      }
      const axiosRes = await apiService.getAllLocations();
      if (!axiosRes || !axiosRes.data) {
        toast.error(`Something went wrong when fetching data from server.`, {autoClose: 10000})
      } else if (axiosRes.data.code !== 200) {
        toast.error(`Server respond with code: ${axiosRes.data.code}`, {autoClose: 10000})
      } else {
        const records = axiosRes.data.data.result;
        let companies = [];
        for (let record of records) {
          const recordCompany = record.company
          if (companies.indexOf(recordCompany) === -1) {
            companies.push(recordCompany)
          }
        }
        setCompanies(companies);
        setData(records)
        setIsLoading(false);
      }
    } catch (err) {
      console.error(err)
      toast.error(err.message, {autoClose: 10000})
    }
  }

  useEffect(() => {
    if (type === "location") {
      setCompany("")
      setLocation("")
      setShowCompanies(true)
      $("#button").fadeOut();
    } else if (type === "reception") {
      setShowCompanies(false);
      setShowLocationNames(false);
      $("#button").fadeIn();
      setButtonText("Proceed to login")
    } else {
      setShowLocationNames(false);
      setShowCompanies(false)
      $("#button").fadeOut();
    }
  }, [type])

  function typeChanged(event) {
    setType(event.target.value);
  }

  useEffect(() => {
    if (company) {
      let locations = [];
      for (let datum of data) {
        if (datum.company === company) {
          locations.push({ userID: datum.userID, locationname: datum.locationname })
        }
      }
      setLocations(locations)
      setShowLocationNames(true)
    } else {
      setLocation("")
      setShowLocationNames(false)
      $("#button").fadeOut();
    }
  }, [company])

  function companyChanged(event) {
    setCompany(event.target.value);
  }

  useEffect(() => {
    if (location) {
      setButtonText("Install")
      $("#button").fadeIn();
    } else {
      $("#button").fadeOut();
    }
  }, [location])

  function locationChanged(event) {
    setLocation(event.target.value);
  }

  useEffect(() => {
    if (sixCode.length === 0 || data.length === 0)
      return;

    const emputy = sixCode.split('').filter(cell => cell === '-')
    if (emputy.length === 0) {
      //Call api to check the verification code
      const chosenUser = data.find(item => item.userID == location);

      const param = {
        username: chosenUser.username,
        clientid: chosenUser.clientID,
        sixcode: sixCode
      }
      AdminService.verifyCode(param)
        .then(
          response => {
            if (response.data.code !== 200) {
              if (countNum > 0) {
                setCountNum(countNum - 1);
                toast.error(response.data.message);
              }
              else {
                backButtonClicked()
              }
            } else {
              var token = response.data.token
              if (!token) return toast.error("Something is wrong, please try again.")
              toast.success("Verified successfully.");
              tokenService.set(token)
              authenticate();
              // return history.replace("/on-load")
              return history.replace("/")
            }
          },
          error => {
            toast.error(Str.STR_SERVER_ACCESS_ERROR);
          }
        )
      setSixCode("------");
    }
  }, [sixCode])

  function codeChanged(value) {
    setSixCode(value);
  }

  async function install() {
    const chosenUser = data.find(item => item.userID == location);
    const param = {
      username: chosenUser.username,
      clientid: chosenUser.clientID,
    }
    const codeResult = await AdminService.requireCode(param)
    if(codeResult.data.code !== 200) {
      toast.error(codeResult.data.message, {autoClose: 10 * 1000});
      return
    }

    setInstallFlag(true);
    toast.success("Verification code has been sent to the manager.", {autoClose: 5000});
  }

  function buttonClicked() {
    localStorage.setItem(Storages.LOCAL_ROLE, type)
    if (type === "location") {
      install();
    } else if (type === "reception") {
      history.replace('/login/');
    }
  }

  function backButtonClicked() {
    setLocation("")
    setCountNum(5)
    setInstallFlag(false)
  }

  return (
    !connections.internet ?
      <Connection msg="It seems like there is no internet connection. Retrying…"/>
    : !connections.server ?
      <Connection msg="We are currently upgrading and updating our service. 
        We apologize for any inconvenience this may cause. 
        Please be patient while we apply these necessary changes, 
        your system will be back up shortly."/>
    :
    <div className='root'>
      <HiddenMenu />
      <img className='top_tap' src={window.locationTopBanner} alt="top_tap" />
      <img className='bottom_tap' src={window.locationBottomBanner} alt="bottom_tap" />
      {isLoading && <ReactLoading className="m-auto" type={"spin"} color={"#0085d2"} />}
      {!isLoading && !installFlag && <div className='center_container'>
        <div className='w-100 d-flex justify-content-center'>
          <div className='col-10 col-md-7 col-xl-4 p-0 '>
            <h3>Welcome to Virtual Sally!</h3>
            <h6>Please choose your installation type</h6>
          </div>
        </div>
        <div className='w-100 d-flex justify-content-center mb-3'>
          <FormControl className="col-10 col-md-7 col-xl-4" variant="standard">
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              label="Type"
              labelId="type-label"
              onChange={typeChanged}
              value={type}
            >
              <MenuItem value={""}>Please choose ...</MenuItem>
              <MenuItem value={"location"}>Location</MenuItem>
              <MenuItem value={"reception"}>Reception</MenuItem>
            </Select>
          </FormControl>
        </div>
        {showCompanies ? <div className='w-100 d-flex justify-content-center mb-3'>
          <FormControl className="col-10 col-md-7 col-xl-4" variant="standard">
            <InputLabel id="org-label">Organization</InputLabel>
            <Select
              label="Organization"
              labelId="org-label"
              onChange={companyChanged}
              value={company}
            >
              <MenuItem value={""}>Please choose ...</MenuItem>
              {companies.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
            </Select>
          </FormControl>
        </div> : ""}
        {showLocationNames ? <div className='w-100 d-flex justify-content-center mb-3'>
          <FormControl className="col-10 col-md-7 col-xl-4" variant="standard">
            <InputLabel id="loc-label">Location</InputLabel>
            <Select
              label="Location"
              labelId="loc-label"
              onChange={locationChanged}
              value={location}
            >
              <MenuItem value={""}>Please choose ...</MenuItem>
              {locations.map((item, index) => <MenuItem key={index} value={item.userID}>{item.locationname}</MenuItem>)}
            </Select>
          </FormControl>
        </div> : ""}
        <div className='w-100 d-flex justify-content-center'>
          <Button className="col-10 col-md-7 col-xl-4 h-5" variant="contained" id="button"
            style={{ display: "none", backgroundColor: "#0085d2", color: "#FFFFFF" }} onClick={buttonClicked}>
            {buttonText}
          </Button>
        </div>
      </div>}
      {!isLoading && installFlag && <div className='center_container'>
        <div className='w-100 d-flex justify-content-center'>
          <div className='col-10 col-md-7 col-xl-4 p-0 '>
            <h3>Please enter your verification code for {data.find(item => item.userID == location)?.username}</h3>
          </div>
        </div>
        <div className='w-100 d-flex justify-content-center mb-3'>
          <ReactInputVerificationCode
            length={6}
            value={sixCode}
            placeholder="-"
            onChange={codeChanged}
          />
        </div>
        <div className='w-100 d-flex justify-content-center'>
          <Button className="col-10 col-md-7 col-xl-4 h-5" variant="contained"
            style={{ backgroundColor: "#0085d2", color: "#FFFFFF" }}
            onClick={backButtonClicked}
          >
            Back
          </Button>
        </div>
      </div>}
      <VersionComponent/>
    </div>
  )
}

FirstPage.prototype = {

}

export default withRouter(FirstPage);
