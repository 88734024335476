import { createSlice } from '@reduxjs/toolkit';


export const TracksMuted=createSlice({
    name:"trackMuted",
    initialState:{},
    reducers:{
        trackMuteChange:(state, action)=>{
            let {id,type,isMuted}=action.payload
            let participant=state[id]
            if(!participant) participant={}
            participant[type]=isMuted
            state[id]=participant
            return state
        }
    }
})

export const { trackMuteChange} = TracksMuted.actions

export default TracksMuted.reducer