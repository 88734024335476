import React, { useMemo } from "react";
import FlagIcon from "@mui/icons-material/Flag";
import { styled, Tooltip } from "@mui/material";
import ReceptionInfo from "./ReceptionInfo";
import ToolBox from "./ToolBox";
import "./styleReception.css";
import { Settings } from "@mui/icons-material";

const Root = styled("div")(({ theme: { palette, mode } }) => ({
  position: "relative",
  height: "8vh",
  textAlign: "center",
  backgroundColor: palette.background[mode],
  "& .footer-small-buttons": {
    padding: "3px 5px",
    marginInline: "3px",
    "& svg": {
      fontSize: "1.5rem",
      color: palette.color[mode],
    }
  }
}));

export default function ReceptionFooter(props) {
  const ToolBoxElement = useMemo(
    () => ToolBox,
    [
      props.locationEngaged,
      props.callState,
      props.playState,
      props.mic,
      props.camera,
      props.allLocations,
      props.locationVolume,
      props.callLoading,
      props.monitorCallStatus,
    ]
  );

  return (
    <Root className="d-flex align-items-center w-100">
      <div style={{marginLeft: "1.2rem", position: "absolute"}}>
        <ReceptionInfo />
      </div>
      <div style={{margin: "0px auto"}}>
        <ToolBoxElement
          handleOpenModalPayReq={props.handleOpenModalPayReq}
          handleRequestForScan={props.handleRequestForScan}
          startCardDetection={props.startCardDetection}
          handleModalPrintFile={props.showPrintFileModal}
          handleLocalCamera={props.handleLocalCamera}
          handleLocalMic={props.handleLocalMic}
          handleCall={props.handleLocationCall}
          handlePlay={props.handleLocationPlay}
          handleOpenGallery={props.handleOpenGallery}
          toggleShareScreen={props.toggleShareScreen}
          callState={props.callState}
          locationEngaged={props.locationEngaged}
          allLocations={props.allLocations}
          playState={props.playState}
          micro={props.mic}
          camera={props.camera}
          locationVolume={props.locationVolume}
          handleExitFullScreenEngaged={props.handleExitFullScreenEngaged}
          captureLocationCameraImage={props.captureLocationCameraImage}
          handleDeviceManagement={props.handleDeviceManagement}
          callLoading={props.callLoading}
          handleOpenMessagingBoard={props.handleOpenMessagingBoard}
          monitorCallStatus={props.monitorCallStatus}
        />
      </div>
      <div 
        style={{
          right: "1.2rem",
          position: "absolute",
        }}
      >
        <Tooltip title="Report an issue">
          <button
            className="btn rounded-circle footer-small-buttons"
            onClick={() => props.setShowModalSetting(true)}
          >
            <Settings fontSize="small" />
          </button>
        </Tooltip>
        <Tooltip title="Open settings">
          <button
            className="btn rounded-circle footer-small-buttons"
            onClick={props.openIssueReporter}
          >
            <FlagIcon fontSize="small" />
          </button>
        </Tooltip>
      </div>
    </Root>
  );
}
