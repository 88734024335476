import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
// import './modal-style.css';
import { Modal, Col, Row } from 'react-bootstrap';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

import apiServices from '../../services/api'
import { toast } from 'react-toastify';
import TimeAgo from 'timeago-react'; // var TimeAgo = require('timeago-react');

import {
    ListItem,
    ListItemIcon,
    ListItemText,
    InputBase,
    IconButton,
  } from "@mui/material";

const PREFIX = 'MessagingBoardModal';

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
  inputDuration: `${PREFIX}-inputDuration`,
  input: `${PREFIX}-input`,
  iconButton: `${PREFIX}-iconButton`,
  color: `${PREFIX}-color`
};

const StyledModal = styled(Modal)(({
  theme: {palette,mode}
}) => ({    
    ['& .modal-content']:{
      backgroundColor:palette.background[mode] + " !important",
      color:palette.color[mode] + " !important"
    },
    '& .modal-header .close' : {
      color:palette.color[mode] + " !important"
    },
  

  [`& .${classes.item}`]: {
    color:palette.color[mode]
  },

  [`& .${classes.inputDuration}`]: {
    border:"1px solid "+palette.color[mode],
    flex: 1,
    padding: "5px",
    fontSize: "16px",
    borderRadius:"5px",
    color:palette.color[mode]  
  },

  [`& .${classes.input}`]: {
      border:"1px solid "+palette.color[mode],
      flex: 10,
      padding: "5px",
      fontSize: "16px",
      borderRadius:"5px",
      color:palette.color[mode]
      
  },

  [`& .${classes.iconButton}`]: {
      padding: '8px',
      borderRadius:0,
      border:0
  },

  [`& .${classes.color}`]: {
      color: palette.color[mode]
  }
}));

export const MessagingBoardModal = ({ showModal, handleClose, location}) => {

    var [loading,setLoading]=useState(true)
    const inputRef=useRef()
    const inputDurationRef=useRef()
    const [boards,setBoards]=useState([])
    const [boardId,setBoardID]=useState(-1)
    const [messages,setMessages]=useState([])
    const [loadingMessages,setLoadingMessages]=useState(false)
    useEffect(()=>{
      if(showModal){
            // get list boards from api
            getBoards()
        }
    },[showModal,location])


    const getBoards=async()=>{
        try {
            if(!location) return // show toast or close modal
            let res=await apiServices.getBoards({location})
            if(res.data.code==0){
              let newBoards=res.data.data.boards

              setBoards(newBoards)
            }
            else {
              toast.warning(res.data.msg,{autoClose:10000})
              setBoards([])
            }
            setLoading(false)

        } catch (error) {
            console.error(error)
            setBoards([])
            toast.error(error.message,{autoClose:10000})
        }
    }

    const Boards=()=>{
      var content=[]
      if(!boards || !boards[0])return <div className='my-2'>No board found.</div>
      for(let b of boards){
        content.push(
          <ListItem  key={b.id} selected={b.id==boardId} onClick={()=>handleOnclickBoard(b.id)} >
          <ListItemText className={classes.item} primary={
            <label className={"mb-0 " + classes.color}>{b.name}</label>
          } />
        </ListItem>
        )
      }
      return content
    }

    const handleOnclickBoard=async(id)=>{
      try {
        setBoardID(id)
        inputRef.current.focus()
        //get messaging and show
        setLoadingMessages(true)
        let res=await apiServices.getBoardMessages({boardId:id})
        if(res.data.code==0) setMessages(res.data.data.messages)
        else  {
          setMessages([])
          toast.warning(res.data.msg, {autoClose: 10000})
        }
      } catch (error) {
        console.error(error)
        toast.error(error.message, {autoClose: 10000})
        setMessages([])        
      }
      setLoadingMessages(false)
    }

    const Messaging=()=>{
      var content=[]
      if(boardId == -1 || !messages[0]) return <div className='my-2'>No Message Found.</div>
      for(let m of messages){
        content.push(
          <ListItem  key={m.id}>
          <ListItemText className={classes.item} primary={
              <span className={classes.color}>
                {m.msg}
              </span>
              }>
          </ListItemText>
              <ListItemIcon className={classes.item} > 
              <TimeAgo
                datetime={m.expired}
                // locale='zh_CN'
              />
              </ListItemIcon>
        </ListItem>
        )
      }
      return content
    }

    
    const handleOnKeyDown=(e)=>{
      if(e.key === 'Enter') handleSubmitInput()
    }

    const handleSubmitInput=async()=>{
      try {
        var msg=inputRef.current.value
        msg=msg.trim()
        if(!msg) return
        var duration=inputDurationRef.current.value
        if (duration - Math.floor(duration) !== 0) {
          return toast.warning('Numbers without decimals are needed in this field.',{autoClose:10000})
        }
        var data={
          msg,
          boardId,
          duration
        }
        let res=await apiServices.sendMessageToBoard(data)
        if(res.data.code==0){
          setMessages(res.data.data.messages)
        }
        else {
          toast.warning(res.data.msg,{autoClose:10000})

        }
        inputRef.current.value=null
      } catch (error) {
        console.error(error)
        toast.error(error.message,{autoClose:10000})
      }
    }

    const hideModal = () => {
      setMessages([]);
      setBoards([]);
      handleClose();
    }
    
    return (
      <StyledModal className={classes.root} show={showModal} size='lg' onHide={hideModal} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered >
      <Modal.Header closeButton>
        <Modal.Title>Messaging Boards {location}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{padding:"0px"}}>
          <Row className='mb-2' style={{ padding:"0px 1rem"}}>
            <Col xs={3} md={4}  style={{height: "48vh",borderRight:"1px solid #dee2e6",overflowX:"hidden",overflowY:"auto"}}>
              {loading ?<div className='my-2'>Loading....</div>: <Boards/>}
            </Col>
            <Col  xs={9} md={8} style={{ height: "48vh",overflowY:"auto",overflowX:'hidden'}}>
              {loadingMessages?
              <div className='my-2'>Loading...</div>:
                <Messaging/>
              }

            </Col>
          </Row>


        
      </Modal.Body>
      <Modal.Footer> 
        <InputBase
           inputProps={{ min:1}}
           disabled={boardId==-1}
            Title="Duration"
            type='number'
            defaultValue={10}
            className={classes.inputDuration}
            placeholder="Type..."
            inputRef={inputDurationRef}
            id="DurationInput"
            endAdornment={
              <b className={classes.color}>M</b>
            }
        />
        <InputBase
          Title="Message"
          disabled={boardId==-1}
          autoFocus 
          className={classes.input}
          placeholder="Type..."
          inputRef={inputRef}
          onKeyDown={handleOnKeyDown}
          id="messagesInput"
      />
        <IconButton edge="end" className={classes.iconButton} disabled={boardId== -1} onClick={handleSubmitInput} aria-label="submit">
            <SendOutlinedIcon className={classes.color} />
        </IconButton>
                  
      </Modal.Footer>
       
    </StyledModal>
    );
}