import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';
import { HiddenMenu } from '../Location/HiddenMenu';
import VersionComponent from '../../Version'
import AdminService from '../../services/api'
import '../Patient/colorbutton.css';

const Connection = (props) => {
  useEffect(()=>{
    checkHealth()
  },[])
  
  const checkHealth=async ()=>{
    try {
      var res=await AdminService.checkHealth()
      if(res.data.code!==0){
        setTimeout(checkHealth,3*1000)
      }
    } catch (error) {
      console.error(error)
      setTimeout(checkHealth,3*1000)
    }
  }
    const { msg } = props;

    return (
      <div className='root'>
        <HiddenMenu />
        <img className='top_tap' src={window.locationTopBanner} alt="top_tap" />
        <img className='bottom_tap' src={window.locationBottomBanner} alt="bottom_tap" />
        <div className='center_container'>
          <div className='d-flex flex-column justify-content-center' style={{width:"65%"}}>
            <ReactLoading className="m-auto" type={"spin"} color={"#0085d2"} />
            <h3 className="mx-auto mt-4 text-center" >{msg}</h3>
            {/* <div className='mx-auto mt-2 color_btn' onClick={() => window.location.reload()} 
              style={{backgroundColor: "#007bff", width: "120px", height: "50px", cursor: "pointer", color: "white", fontSize: "20px", fontFamily: "Medium"}}>
                <RefreshIcon/>
                Reload
            </div> */}
          </div>
        </div>
        <VersionComponent/>
      </div>
    )
};

export default Connection;