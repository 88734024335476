import React, { useEffect, useState, useContext, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import * as $ from 'jquery';

import { Button } from '@mui/material';
import CallEndIcon from '@mui/icons-material/CallEnd';
import BusyArea from '../../Components/Patient/BusyArea';
import WaitArea from '../../Components/Patient/WaitArea';
import HoldArea from '../../Components/Patient/HoldArea';
import AudioVolume from '../../Components/Controls/AudioVolume';
import AdminService from '../../services/api';
import { toast } from 'react-toastify';
import Constant from '../../constants/constant';
import Str from '../../constants/string';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { addMic, deleteMic, deleteMics,selectMic, updateDefaultMic } from '../../redux/micSlice';
import { addCamera, selectCamera, deleteCamera, deleteCameras } from '../../redux/cameraSlice';
import Storages from '../../constants/storages';
import { SocketContext } from '../../context/socket';
import { addSpeaker, deleteSpeaker, deleteSpeakers, selectSpeaker, updateSpeaker } from '../../redux/speaker';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import tokenService from '../../services/tokenService';
import jitsiConnectionConfig from '../../constants/jitsiConnectionConfig'


var checkCallStateInterval = null;
var doctorSaidNotInCallOnce = false;
const Patient = React.memo((props) => {
    const [isWait, setWait] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const [isCall, setCall] = useState(false);
    const [isHold, setHold] = useState(false);
    const isHoldRef=useRef(false)
    const [isMuted, setMuted] = useState(false);
    const [joinState, setJoinState] = useState(false);
    const [localVideoTrack, _setLocalVideoTrack] = useState(null);
    const localVideoTrackRef = useRef(null);
    localVideoTrackRef.current = localVideoTrack;
    const setLocalVideoTrack = (newVal) => {
      _setLocalVideoTrack(newVal)
      localVideoTrackRef.current = newVal
    }
    const [localAudioTrack, _setLocalAudioTrack] = useState(null);
    const localAudioTrackRef = useRef(null);
    localAudioTrackRef.current = localAudioTrack;
    const setLocalAudioTrack = (newVal) => {
      _setLocalAudioTrack(newVal)
      localAudioTrackRef.current = newVal
    }
    const [userVideoTrack, setUserVideoTrack] = useState(null);
    const [userAudioTrack, setUserAudioTrack] = useState(null);
    const [volumeValue, setVolumeValue] = React.useState(localStorage.getItem('location_volume') || 75);
    const [connectTag, setConnectTag] = useState("");
    const [remoteUserData, setRemoteUserData] = useState([]);
    const [prevIsSleep, setPrevIsSleep] = useState(false);
    const [isShareScreenOn, setIsShareScreenOn] = useState(false);
    const [isVideoPaused, setIsVideoPaused] = useState(false);
    const [connectionIssue, setConnectionIssue] = useState(false)
    const socket = useContext(SocketContext);

    const mics = useSelector((state) => state.mics);
    const cameras = useSelector((state) => state.cameras);
    const speakers = useSelector((state) => state.speakers);
    // use devices for send list devices to reception
    const allDevices=useRef({
      cameras,microphones:mics,speakers
    })
    const [checkDevice, setCheckDevice] = useState(false);
    const [updateCameraDevice, setUpdateCameraDevice] = useState(false);
    const [displayNameReception,setDisplayNameReception]=useState('')
    const [disableCallEnd,setDisableCallEnd]=useState(false)

    const dispatch = useDispatch();
    const isSleep = useSelector((state) => state.isSleep)
    const connection = React.useRef(null);
    const room = React.useRef(null);
    const receptionId = React.useRef(null);
    const receptionName = React.useRef("");
    const isSignalAccept = React.useRef(false);
    const callStatus = React.useRef(false);
    const isLeave = React.useRef(false);
    const incomingCallTime = React.useRef(0);
    const resetLocalVideoConsTimeout = useRef(null);
    const currentLocalVideoCons = useRef({});
    const lastReceptionResToCallState = useRef(null)
    let intervalIncomingCall = React.useRef(null);
    var callToken =React.useRef(null)
    let listRemouteUsers = [];
    let listRemoteUserData = [];
    let localTracks = [];
    let isJoined = false;
    let lastTime;
    const videoConstraintsRef=useRef()
    /*
    connect signal from mainLocation page to this
    */
    props.biRef.inComingCall = inComingCall;
    props.biRef.updateCallTag = updateCallTag;
    props.biRef.unload = unload;
    props.biRef.checkRoom = checkRoom;
    /**/

    useEffect(()=>{
      if(isSleep!= prevIsSleep){
        setPrevIsSleep(isSleep)
        if(isSleep ==0){
          isLeave.current=false
          if(window.electron) {
            window.electron.reload();
          } else {
            window.location.replace("#/")
          }
        }
        if(isSleep ==1 ){
          // line bellow commented because it is already in unload.
          // isLeave.current=true
          unload();
          updateUserStatus(Constant.LOGIN);
        }
      }
    },[isSleep])

    useEffect(()=>{
        if(receptionName.current){
            // get display name from api
            getDisplayNameReception(receptionName.current)
        }
    },[receptionName.current])

    /**
     * Function that check sleep and refresh on waking up.
     */
    function checkSleeping() {
        let currentTime = (new Date()).getTime();

        if (currentTime > (lastTime + Constant.SLEEP_INTERVAL * 2)) {  // ignore small delays
            console.alert("Constant.SLEEP_INTERVAL * 2")
            setTimeout(function () {
                console.alert("Patient inside checkSleeping timeout. Will reload now.");
                //enter code here, it will run after wake up
                if(window.electron) {
                  window.electron.reload();
                } else {
                  window.location.replace("#/")
                }
            }, Constant.TEN_SEC_INTERVAL);
        }
        lastTime = currentTime;
    }

    /**
     * Function that send sokect to jitsi server
     */
    function sendSocket() {
        if (room.current && room.current.isModerator()) {
            return true;
        }
        else {
            return false;
        }
    }

    function countIncomingCallTime() {
        if (!callStatus.current) {
            incomingCallTime.current = incomingCallTime.current + 1;
        }
        else {
            incomingCallTime.current = 0;
        }

        if (incomingCallTime.current > 12) {
          console.alert(1201, "countIncomingCallTime > 12. setBusy(true)")
          clearInterval(intervalIncomingCall.current);
          setBusy(true);

          // hide busy area after 15 seconds
          setTimeout(()=> {
            callEnd('No_Answered')
          }, 15000)
        }
    }

    useEffect(() => {
        if (props.isShowCall) {
            incomingCallTime.current = 0;
            intervalIncomingCall.current = setInterval(countIncomingCallTime, Constant.TEN_SEC_INTERVAL)
            setBusy(false);
            setMuted(false);
        }
        else {
            clearInterval(intervalIncomingCall.current)
        }
    }, [props.isShowCall])

    useEffect(() => {
        if (isCall) {
            /**show connecting message */
            setConnectTag(Str.STR_CALL_CONNECTING);
            setTimeout(function () {
                setConnectTag(Str.STR_CALL_CONNECTED);
            }, Constant.CALL_CONNECT_DELAY);
            // setVolumeValue(Constant.MAX_VOLUME_VALUE);
            incomingCallTime.current = 0;
            clearInterval(intervalIncomingCall.current);
        }
    }, [isCall])

    useEffect(() => {
        if (isCall && !isHold) {
          $("#userAudio")[0].volume = volumeValue / Constant.MAX_VOLUME_VALUE;
        }
    }, [volumeValue, isHold])

    useEffect(() => {
        if (!joinState) {
            return;
        }
        var currentCameras = [...cameras];
        var currentMics = [...mics];
        var currentSpeakers = [...speakers];
        if (window.JitsiMeetJS.mediaDevices.isDeviceChangeAvailable(Str.STR_INPUT)) {
            window.JitsiMeetJS.mediaDevices.enumerateDevices((devices) => {
                const videoInputDevices = devices.filter((d) => d.kind === Str.STR_VIDEO_INPUT);
                const audioInputDevices = devices.filter((d) => d.kind === Str.STR_AUDIO_INPUT);
                const audioOutputDevices = devices.filter((d) => d.kind === Str.STR_AUDIO_OUTPUT);
                
                //add camera devices in redux when it is connected
                videoInputDevices.forEach((cell, i) => {
                    const index = currentCameras.findIndex((camera) => camera.deviceId === cell.deviceId)
                    if (index < 0) {
                        const item = { deviceId: cell.deviceId, label: cell.label, selected: false }
                        dispatch(addCamera(item));
                        if (currentCameras.length === 0 && i === 0) {
                            var cameraDeviceId = videoInputDevices[0].deviceId;
                            // localStorage.setItem(Storages.LOCAL_CAMERA_ID,cameraDeviceId)
                            dispatch(selectCamera({ index: 0 }));
                            if(!isShareScreenOn){
                                window.JitsiMeetJS.createLocalTracks({
                                    devices: [Str.STR_VIDEO],
                                    cameraDeviceId: cameraDeviceId
                                })
                                .then(onLocalTracks)
                                .catch(error => {
                                  handleErrorTrack(error,1299,'camera')
                                  // let lastCameraId=localStorage.getItem(Storages.LOCAL_CAMERA_ID)
                                  // if(videoInputDevices[0].deviceId !=lastCameraId){
                                  //   dispatch(selectCamera({deviceId:lastCameraId}))
                                  // }
                                });
                            }
                        }
                    }
                })

                //delet camera device in redux when it is disconnected
                let isSelectedCamera = true;
                if (videoInputDevices.length > 0) {
                    currentCameras.forEach((cell) => {
                        const index = videoInputDevices.findIndex((camera) => camera.deviceId === cell.deviceId)
                        if (index < 0) {
                            dispatch(deleteCamera({deviceId:cell.deviceId}));
                            if (cell.selected) {
                                isSelectedCamera = false;
                            }
                        }
                    })
                }
                else {
                    if (currentCameras.length > 0)
                        dispatch(deleteCameras());
                }

                if (!isSelectedCamera)
                    setUpdateCameraDevice(prev => !prev);

                // microphone
                if (audioInputDevices.length > 0) {
                  audioInputDevices.forEach((cell) => {
                    const index = currentMics.findIndex((mic) => mic.label === cell.label)
                    if (index < 0) {
                      if(cell.deviceId == "communications") {
                          return;
                      }
                      let newMic={
                        deviceId: cell.deviceId,
                        label: cell.label,
                      }
                      dispatch(addMic(newMic));
                    }
                  })
                  currentMics.forEach((m)=>{
                    let index=audioInputDevices.findIndex((mic) => mic.label === m.label)
                    if(index<0){
                      if(m.deviceId==='default'){
                        let input=audioInputDevices.find((mic) => mic.deviceId==='default')
                        dispatch(updateDefaultMic({deviceId:input.deviceId,label:input.label}))
                        return
                      }
                      dispatch(deleteMic({label:m.label}))
                      if(m.selected){
                        dispatch(selectMic({deviceId:audioInputDevices[0].deviceId}))
                        // localStorage.setItem(Storages.LOCAL_MIC_ID,audioInputDevices[0].deviceId)
                      }
                    }
                  })
                }
                else {
                    if (currentMics.length > 0){
                      dispatch(deleteMics());
                    }
                }
                // speaker
                if (audioOutputDevices.length > 0) {
                  audioOutputDevices.forEach((cell)=>{
                    const index = currentSpeakers.findIndex((s) => s.label === cell.label)
                    if (index < 0) {
                      if(cell.deviceId == "communications") {
                          return;
                      }
                      let label = cell.label;
                      let sp = {  deviceId: cell.deviceId, label}
                        dispatch(addSpeaker(sp));
                    }
                  })
                  currentSpeakers.forEach((sp)=>{
                    let index=audioOutputDevices.findIndex((s) => s.label === sp.label)
                    if(index<0){
                      if(sp.deviceId==='default'){
                        let out=audioOutputDevices.find((ao)=>ao.deviceId==='default')
                        dispatch(updateSpeaker({deviceId:sp.deviceId,label:out.label}))
                        setVolume()
                        return
                      }
                      dispatch(deleteSpeaker({deviceId:sp.deviceId}))
                      if(sp.selected){
                        let deviceId=audioOutputDevices[0].deviceId
                        localStorage.setItem(Storages.LOCAL_SPEAKER_ID,deviceId)
                        dispatch(selectSpeaker({deviceId}))
                        document.getElementById('userAudio').setSinkId(deviceId)
                        document.getElementById("testSpeaker") && document.getElementById("testSpeaker").setSinkId(deviceId)
                        setVolume()
                      }
                    }
                  })
                }
                else {
                  if (currentSpeakers.length > 0)
                      dispatch(deleteSpeakers());
              }
            })
        }
    }, [checkDevice])

    useEffect(() => {
      if(props.showKioskFrame) {
        setIsShareScreenOn(true)
      } else {
        setIsShareScreenOn(false)
      }
    }, [props.showKioskFrame])

    useEffect(() => {
      if(localVideoTrack) {
        room.current.removeTrack(localVideoTrack)
      }
      if(isShareScreenOn) {
        window.JitsiMeetJS.createLocalTracks({
          devices: ["desktop"],
        })
        .then(onLocalTracks)
        .catch(error => handleErrorTrack(error,1297,'desktop'));
      } else {
        setUpdateCameraDevice(prev => !prev);
      }
    }, [isShareScreenOn])

    useEffect(() => {
      if (cameras.length > 0 && joinState) {
          let cameraDeviceId = localStorage.getItem(Storages.LOCAL_CAMERA_ID)
          if(!cameraDeviceId){
              cameraDeviceId = cameras[0].deviceId;
              // localStorage.setItem(Storages.LOCAL_CAMERA_ID,cameraDeviceId)
              dispatch(selectCamera({ deviceId: cameraDeviceId }));
          }

          window.JitsiMeetJS.createLocalTracks({
              devices: [Str.STR_VIDEO],
              cameraDeviceId: cameraDeviceId
          })
          .then(onLocalTracks)
          .catch(error =>{
            handleErrorTrack(error,1295,'camera')
          });
      }
    }, [updateCameraDevice])

    useEffect(() => {
      if(localVideoTrack) {
        // When track is changed, we show the original video for a while (For no good reason!), 
        // then we fall back to default
        // This also called when the track is first attached (Page loads)
        startResetLocalVideoConsTimeout()
      }
    }, [localVideoTrack])

    const sendListDevices=async ({from})=>{
      try {
        // send volume speaker
        let data=allDevices.current
        if(window.electron){
          try {
            var res=await window.electron.getVolume()
            if(res.code===0) data.volume=res.data
            else console.error(res.msg)
          } catch (error) {
              console.error(error)
          }
        }
        data.myUsername = props.match.params.username;
        await AdminService.sendMessage({
          event:"list-devices",
          to:from,
          msg:data
        })
      } catch (error) {
        console.error(1296,error)
      }

    }

    useEffect(() => {
      socket.on("connect", () => {
        setConnectionIssue(false)
      })
      socket.on('msg',({event, msg,from})=>{
        if(event === 'toggle-share-screen'){
          setIsShareScreenOn(prev => !prev)
        }
        if (event === "sendVolumeToLocation") {
          if(msg.volume < 0) {
            msg.volume = 0
          } else if(msg.volume > 100) {
            msg.volume = 100
          }
          setVolumeValue(msg.volume);
        }
        if(event === "list-devices"){
          console.alert(1176, "get request list devices from:", from)
          sendListDevices({from})
        }
        if(event==='update-mic'){
          var {deviceId}=msg
          if(!deviceId) return
          // Stay on the same microphone when on hold.
          if(isHoldRef.current) return
          // localStorage.setItem(Storages.LOCAL_MIC_ID,deviceId)
          dispatch(selectMic({deviceId:deviceId}))
        }
        if(event==='update-camera'){
          var {deviceId}=msg
          if(!deviceId) return
          // localStorage.setItem(Storages.LOCAL_CAMERA_ID,deviceId)
          dispatch(selectCamera({deviceId:deviceId}))
        }
        if(event ==='update-speaker'){
          var {deviceId}=msg
          if(!deviceId) return
          localStorage.setItem(Storages.LOCAL_SPEAKER_ID,deviceId)
          dispatch(selectSpeaker({deviceId:deviceId}))
          document.getElementById('userAudio') && document.getElementById('userAudio').setSinkId(deviceId)
          if(document.getElementById('testSpeaker')) {
            document.getElementById('testSpeaker').setSinkId(deviceId)
          }
          if(window.JitsiMeetJS)window.JitsiMeetJS.mediaDevices.setAudioOutputDevice(deviceId)   
          setVolume()
        }
        if(event === 'set-volume'){
          localStorage.setItem("volume",msg)
          window.electron.setVolume(msg)
        }
        if(event === 'disable_call_end'){
          setDisableCallEnd(true)
        }
      })
      socket.on("sendIssueReport", async (data) => {
        try{
          console.alert(2701701, "sendIssueReport request", data)
          const screenshotRes = await window.electron.takeScreenShot(0);
          if(screenshotRes.code !== 0) {
            console.error(2701703, screenshotRes)
          } 
          
          if(allDevices.current) {
            console.alert(2701705, "cameras", allDevices.current.cameras)
            console.alert(2701708, "mics", allDevices.current.microphones)
            console.alert(2701710, "speakers", allDevices.current.speakers)
          }
          
          const logRes = await window.electron.getLastLog();
          if(logRes.code !== 0) {
            console.error(2701712, logRes)
          }
          const logFile = logRes.data?.log;
          const screenshotFile = screenshotRes.data?.image
          const reportID = data.reportID;
          const updateRes = await AdminService.updateIssueReport({
            locLog: logFile, 
            locSc: screenshotFile,
            reportID
          })
          if(updateRes?.data.code !== 0) {
            console.error(2701715, updateRes)
          }
        } catch (err) {
          console.error(2701720, err)
        }
      })
      socket.on("endCurrentCall", async (data) => {
        callEnd('Failed')
        if(data.reason == "receptionOffline") {
          toast.warning(window.i18n.getString("callEndRecOffline"), {autoClose: 7 * 1000})
        }
      })
      socket.on("disconnect",()=>{
        // toast.warning(window.i18n.getString("noInternetConnection"),5000)
        // if(callStatus.current === true) callEnd()
        setConnectionIssue(true)
      })
      socket.on("callToken",(data)=>{
        callToken.current=data.token
      })
      socket.on("hangUpHoldCall", () => {
        toast.info(window.i18n.getString("callEndTranToNewReception"))
        callEnd('Transfer')
      })
      socket.on("callHoldStatus", (data)=>{
        if(data.hold) {
          callPause();
        } else {
          callPlay();
        }
      })
      socket.on("setLocalVideoCons", (newCons) => {
        if(
          currentLocalVideoCons.current.maxFps !== newCons.maxFps 
          || currentLocalVideoCons.current.maxHeight !== newCons.maxHeight
          || currentLocalVideoCons.current.aspectRatio !== newCons.aspectRatio
        ) {
          setLocalVideoCons(newCons)
        }
        // while this socket is called from server, we don't fall back to default. 
        // From Doctor.js, updateLastActivity is called every Constant.updateActivityIntervalDelay ms.
        // So if it's not called for twice this duration (we wait 1 second more for socket delay), 
        // we fall back to default
        startResetLocalVideoConsTimeout();
      })
  
      let jitsiUrl;
      if (window.JitsiMeetJS) {
          if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            console.alert(1211, "!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices");
            return;
          }

          setWait(true);
          window.$ = $
          window.jQuery = $

          const initOptions = {
            disableAudioLevels: true,
            enableAnalyticsLogging: false
          }
          window.JitsiMeetJS.init(initOptions);
          window.JitsiMeetJS.setLogLevel(window.JitsiMeetJS.logLevels.ERROR);

          const token = localStorage.getItem("jitsiJwt")
          let jitsiConfig;
          if(localStorage.getItem("isJaas")) {
            jitsiConfig = jitsiConnectionConfig("jaas")
          } else {
            jitsiConfig = jitsiConnectionConfig()
          }

          jitsiUrl = jitsiConfig.hosts.domain
          localStorage.setItem("finalJitsiUrl", jitsiUrl)
          connection.current = new window.JitsiMeetJS.JitsiConnection(null, token, jitsiConfig);

          connection.current.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, onConnectionSuccess);
          connection.current.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_FAILED, onConnectionFailed);
          connection.current.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, onConnectionDisconnected);

          connection.current.addEventListener( window.JitsiMeetJS.errors.track.TRACK_IS_DISPOSED , (err)=>console.error(3130,err));
          connection.current.addEventListener( window.JitsiMeetJS.errors.conference.RESERVATION_ERROR  , (err)=>console.error(3133,err));
          connection.current.addEventListener( window.JitsiMeetJS.errors.conference.VIDEOBRIDGE_NOT_AVAILABLE   , (err)=>console.error(3132,err));
          connection.current.addEventListener( window.JitsiMeetJS.errors.track.NOT_FOUND, (err)=>console.error(3134,err));
          connection.current.addEventListener( window.JitsiMeetJS.errors.track.TRACK_NO_STREAM_FOUND , (err)=>console.error(3135,err));
          // window.JitsiMeetJS.mediaDevices.addEventListener(window.JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED, onDeviceListChanged);
          connection.current.connect();

          dispatch(deleteMics());
          dispatch(deleteCameras());
          if (window.JitsiMeetJS.mediaDevices.isDeviceChangeAvailable(Str.STR_INPUT)) {
              window.JitsiMeetJS.mediaDevices.enumerateDevices((devices) => {
                  const videoInputDevices = devices.filter((d) => d.kind === Str.STR_VIDEO_INPUT);
                  const audioInputDevices = devices.filter((d) => d.kind === Str.STR_AUDIO_INPUT);
                  const audioOutpuDevices = devices.filter((d) => d.kind === Str.STR_AUDIO_OUTPUT);
                  var micDeviceId = localStorage.getItem(Storages.LOCAL_MIC_ID);
                  var cameraDeviceId= localStorage.getItem(Storages.LOCAL_CAMERA_ID);
                  var speakerDeviceId= localStorage.getItem(Storages.LOCAL_SPEAKER_ID);
                  var cameraIndex = -1, micIndex = -1,spIndex=-1;
                  audioInputDevices.forEach((item, index) => {
                    if(item.deviceId == "communications") {
                        return;
                    }
                    let label = item.label;
                    let mic = { id: index, deviceId: item.deviceId, label, selected: false }
                    if (item.deviceId === micDeviceId) {
                        micIndex = index;
                        mic.selected = true;
                    }
                    dispatch(addMic(mic));   
                  })  
                  if (micIndex === -1 && audioInputDevices.length) {
                      micDeviceId = audioInputDevices[0].deviceId;
                      // localStorage.setItem(Storages.LOCAL_MIC_ID,micDeviceId)
                      dispatch(selectMic({ deviceId: micDeviceId }));
                  }
                  window.JitsiMeetJS.createLocalTracks({
                      devices: [Str.STR_AUDIO],
                      micDeviceId: micDeviceId
                  })
                  .then(onLocalTracks)
                  .catch(error => {
                    handleErrorTrack(error,1294,'microphone')
                    // console.error(1294, error)
                    //   toast.error(`There is an issue with audio track. Please reload. 
                    //       If this issue persists, please contact customer support.
                    //   `)
                  });


                  // Speaker
                  audioOutpuDevices.forEach((item, index) => {
                    if(item.deviceId == "communications") {
                        return;
                    }
                    let label = item.label;
                    // if(label) {
                    //     label = label.trim()
                    //     if(label.startsWith("(")) {
                    //         const indexOfClose = label.indexOf(")")
                    //         label = label.slice(1, indexOfClose) + label.slice(indexOfClose+1)
                    //     }
                    // }

                    let sp = { id: index, deviceId: item.deviceId, label, selected: false }
                    if (sp.deviceId === speakerDeviceId) {
                        spIndex = index;
                        sp.selected = true;
                    }
                    dispatch(addSpeaker(sp));   
                  })  
                  if (spIndex === -1 && audioOutpuDevices.length) {
                    speakerDeviceId = audioOutpuDevices[0].deviceId;
                    localStorage.setItem(Storages.LOCAL_SPEAKER_ID,speakerDeviceId)
                    dispatch(selectSpeaker({ deviceId: speakerDeviceId }));
                  }
                  setVolume()
                  if(document.getElementById('userAudio')) {
                    document.getElementById('userAudio').setSinkId(speakerDeviceId)
                  }
                    // document.getElementById("testSpeaker").setSinkId(speakerDeviceId)

                  // const cameraDeviceId = videoInputDevices.length > 0 ? videoInputDevices[0].deviceId : null;
                  videoInputDevices.forEach((item, index) => {
                      let camera = { id: index, deviceId: item.deviceId, label: item.label, selected: false }
                      if (item.deviceId === cameraDeviceId) {
                        cameraIndex = index;
                        camera.selected = true;
                      }
                      dispatch(addCamera(camera));
                  })

                  if (cameraIndex === -1 && videoInputDevices.length) {
                    cameraDeviceId = videoInputDevices[0].deviceId;
                    // localStorage.setItem(Storages.LOCAL_CAMERA_ID,cameraDeviceId)
                    dispatch(selectCamera({ index: 0 }));
                  }
                  
                  window.JitsiMeetJS.createLocalTracks({
                      devices: [Str.STR_VIDEO],
                      cameraDeviceId: cameraDeviceId
                  })
                  .then(onLocalTracks)
                  .catch(error => {
                    handleErrorTrack(error,1293,'camera')
                    // console.error(1293, error)
                    // toast.error(`There is an issue with video track. Please reload. 
                    // If this issue persists, please contact customer support.
                    // `)
                  });

              })
          }

          if($("#userVideo")[0]) {      
            $("#userVideo")[0].addEventListener("pause", videoPaused)
            $("#userVideo")[0].addEventListener("play", videoPlayed)
          }
      }

      setPrevIsSleep(isSleep)
      // check sleep computer timer
      lastTime = (new Date()).getTime();
      let intervalId = setInterval(checkSleeping, Constant.SLEEP_INTERVAL);

      // send socket timer
      let intervalSocket = setInterval(sendSocket, Constant.FIVE_MINUTES);

      //check devices timer
      let intervalDevices = setInterval(() => {
          setCheckDevice(prev => !prev);
      }, Constant.FIVE_SECONDS)


      if (window.electron) {
        window.electron.userLeave(async ()=>{
          try{
            var token = tokenService.get();
            var data={
              username:localStorage.getItem(Storages.LOCAL_LOGINED_USER),
            }
            if(token){
              await AdminService.userLeave(data)
              await unload()
              window.electron.allWindowsClose()
            } else {
              await unload()
              window.electron.allWindowsClose()
            }
          } catch (err) {
            console.error(1292, err)
            window.confirmAsync.show(
              <h6 style={{fontSize: "1.35rem", marginBottom: "0px"}}>Error</h6>, 
              <span style={{fontSize: "1.15rem", marginBottom: "0px"}}>
                There was an error when trying to exit. Do you want to exit anyway?
              </span>, 
              [
                { value: 1, color: "primary", text: "Yes", close: 1 }, 
                { value: 0, color: "default", text: "No", close: 1 }
              ]
            ).then((value) => {
              if(value === 1) {
                window.electron.allWindowsClose()
              }
            })
          }
        });
        window.electron.startTracerouteInterval(process.env.REACT_APP_API_URL, jitsiUrl)
      }

      props.childCallEnd.current = callEnd;
      return (() => {
        if($("#userVideo")[0]) {
          $("#userVideo")[0].removeEventListener("pause", videoPaused)
          $("#userVideo")[0].removeEventListener("play", videoPlayed)
        }
        socket.off("msg");
        socket.off("sendIssueReport")
        socket.off("endCurrentCall")
        socket.off("callToken")
        socket.off("hangUpHoldCall")
        socket.off("callHoldStatus");
        clearInterval(intervalId);
        clearInterval(intervalSocket);
        clearInterval(intervalDevices);
        if(room && room.current) {
          room.current.leave();
        }
        socket.disconnect()
      })
    }, []);

    /**
    * This function is called when the connection fail.
    */
    // function onDeviceListChanged(devices) {
    // }
    useEffect(() => {
      // update list devices
      let all=allDevices.current
      all['microphones']=mics
      allDevices.current=all


      if (!joinState || room.current === null) {
          return;
      }

        mics.forEach((cell) => {
            if (cell.selected === true) {
                if (localAudioTrack) {
                    // localAudioTrack.dispose();
                }

                window.JitsiMeetJS.createLocalTracks({
                    devices: [Str.STR_AUDIO],
                    micDeviceId: cell.deviceId
                }).then(onLocalTracks).catch(error => {
                  handleErrorTrack(error,1475,'microphone')
                  let lastMicId=localStorage.getItem(Storages.LOCAL_MIC_ID)
                  if(cell.deviceId !=lastMicId){
                    dispatch(selectMic({deviceId:lastMicId}))
                  }
                });
            }
        })
  }, [mics])

  useEffect(() => {
    // update list devices
    let all=allDevices.current
    all['cameras']=cameras
    allDevices.current=all


    var selected=cameras.find((cell)=>cell.selected===true)
    if(!selected || !selected.deviceId) return 
    const deviceId=selected.deviceId
    if (!joinState || room.current === null) {
      // location is sleep now, but local video should be update.
      navigator.mediaDevices.getUserMedia({
          audio:false,
          video:{deviceId: { exact: deviceId }
      }}).then((steam)=>{
          var localVideoEl=document.getElementById('localVideo')
          if(localVideoEl) localVideoEl.srcObject=steam
      }).catch((err)=>{
        console.error(1990,err)
        // toast.error(window.i18n.getString("mediaTrackError"));
      });
      return;
    }
    if (localVideoTrack) {
        // localVideoTrack.dispose();
    }

    window.JitsiMeetJS.createLocalTracks({
        devices: [Str.STR_VIDEO],
        cameraDeviceId: deviceId
    }).then(onLocalTracks).catch(error => {
      handleErrorTrack(error,1477,'camera')
      let lastCameraId=localStorage.getItem(Storages.LOCAL_CAMERA_ID)
      if(deviceId !=lastCameraId){
        dispatch(selectCamera({deviceId:lastCameraId}))
      }
    });
  }, [cameras])

  const videoPaused = () => {
    setIsVideoPaused(true)
    if(isCall) {
      console.alert(5901, "Video paused during a call with", receptionName.current)
    }
  }

  const videoPlayed = () => {
    setIsVideoPaused(false)
  }
  
  useEffect(()=>{
    // update list devices
    let all=allDevices.current
    all['speakers']=speakers
    allDevices.current=all
  },[speakers])

  async function inComingCall(strType,color) {
      if (room.current !== null) {
          let token=await requestCall() 
          callToken.current=token
          updateCallTag(strType,color);
          //broadcast incoming call message to all users.
          room.current.setLocalParticipantProperty(props.match.params.username, Constant.NONE_VALUE);
          room.current.setLocalParticipantProperty(Constant.CALL_INCOMING_TOKEN, token);
          room.current.setLocalParticipantProperty(props.match.params.username, Constant.CALL_INCOMING_VALUE);
      }
  }


    const onConnectionSuccess = () => {
      if (isLeave.current) {
        // sometimes an asleep user reload. The user will be loaded with sleep status but it will also joins the jitsi room.
        // this if will prevent such thing to happen
        return
      }

      const roomName = localStorage.getItem(Storages.LOCAL_ROOM_NAME)
      console.alert(1215, "onConnectionSuccess, roomName:", roomName)
        const confOptions = {
          openBridgeChannel: true,
          // enabling p2p will cause issues when setting lastN to 0 or 1
          p2p:{enabled:false}
        };
        room.current = connection.current.initJitsiConference(roomName, confOptions);
        room.current.on(window.JitsiMeetJS.events.conference.TRACK_ADDED, onRemoteTrack);
        room.current.on(window.JitsiMeetJS.events.conference.TRACK_REMOVED, onRemoveTrack);
        room.current.on(window.JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED, onChangeName);
        room.current.on(window.JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);


        room.current.on( window.JitsiMeetJS.errors.track.TRACK_IS_DISPOSED , (err)=>console.error(3130,err));
        room.current.on( window.JitsiMeetJS.errors.conference.RESERVATION_ERROR  , (err)=>console.error(3133,err));
        room.current.on( window.JitsiMeetJS.errors.conference.VIDEOBRIDGE_NOT_AVAILABLE   , (err)=>console.error(3132,err));
        room.current.on( window.JitsiMeetJS.errors.track.NOT_FOUND, (err)=>console.error(3134,err));
        room.current.on( window.JitsiMeetJS.errors.track.TRACK_NO_STREAM_FOUND , (err)=>console.error(3135,err));
        
        
        
        room.current.on(window.JitsiMeetJS.events.conference.USER_JOINED, (id, user) => {
          console.alert(1217, "onUserJoined, id:", id, "user:", user.getDisplayName())
          
            let isFind = false;
            listRemouteUsers.forEach((cell, index) => {
                if (cell.id === id) {
                    cell.user = user;
                    listRemouteUsers[index] = cell;
                    isFind = true;
                }
            });
            if (isFind) {
                return;
            }
            let new_user = { id: id, user: user };
            listRemouteUsers.push(new_user);
        });
        room.current.on(window.JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED, handleParticipantPropertyChange);
        room.current.on(window.JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
        room.current.on(window.JitsiMeetJS.events.conference.CONFERENCE_LEFT, onConferenceLeft);
        room.current.on(window.JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, track => {
            if (track.getType() === Str.STR_VIDEO && track.getParticipantId() === receptionId.current) {
                setMuted(track.isMuted());
            }
        });
        room.current.on(window.JitsiMeetJS.events.conference.ENDPOINT_MESSAGE_RECEIVED, (sender, data) => {
          const senderID = sender.getId()
          if(data.type == "inCallQuestion") {
            if(callStatus.current && senderID == receptionId.current) {
              room.current.sendEndpointMessage(receptionId.current, {type: "inCallAnswer", status: "inCall"});
            } else {
              room.current.sendEndpointMessage(receptionId.current, {type: "inCallAnswer", status: "notInCall"});
            }
          }
          if(callStatus.current && senderID == receptionId.current && data.type == "inCallAnswer") {
            if(data.status === "notInCall") {
              // sometimes, reception hangs up call which will result in callEnd to be called. 
              // In the meanwhile, this socket will be called which will cause callEnd to be called again. 
              // This will cause issues. So we ignore the first answer to notInCall to bypass this issue
              if(doctorSaidNotInCallOnce) {
                doctorSaidNotInCallOnce = false;
                console.error(1192, "reception respond with notInCall", receptionName.current)
                toast.warning(window.i18n.getString("callEndRecOffline"), {autoClose: 7 * 1000})
                callEnd("ReceptionNotInCall")
              } else {
                doctorSaidNotInCallOnce = true;
              }
            } else {
              lastReceptionResToCallState.current = Date.now();
            }
          }
        })
        // allow local tracks to be loaded, video track takes some time to load
        setTimeout(()=>{
            localTracks.map((localTrack) => {
                room.current && room.current.addTrack(localTrack);
            });
            room.current.setDisplayName(props.match.params.username);
            if(isSleep==0) {
              room.current.join();
              // jitsiMeetId will be empty of not jaas.
              AdminService.updateSelfPartId(room.current.myUserId(), localStorage.getItem("jitsiMeetId")).then((res) => {
                const data = res.data;
                if(data.code !== 0) {
                  console.error("6752: ", data)
                  toast.error("6752: " + window.i18n.getString("serverError"), {autoClose: 7 * 1000});
                }
              }).catch((err) => {
                console.error("6753: ", err)
                toast.error("6752: " + window.i18n.getString("serverError"), {autoClose: 7 * 1000})
              })
            }
        },1000)
    }


    const onConnectionFailed = (error) => {
      console.alert(1218, "onConnectionFailed", error)

      // hide busy area if jitsi connection failed
      setBusy(false);

      setTimeout(function () {
          //enter code here, it will run after wake up
          if(window.electron) {
            window.electron.reload();
          } else {
            window.location.replace("#/")
          }
      }, Constant.TEN_SEC_INTERVAL);
    }

    const onConnectionDisconnected=()=>{
      console.alert(1901, "onConnectionDisconnected")
      // hide busy area if jitsi disconnected
      setBusy(false);

      // if unload is called once and isLeave is true, there is no need to call it again.
      if(!isLeave.current) {
        unload()
      }
    }

    async function unload() {
      try {
        console.alert(1220, "unload")

        isLeave.current = true;
        try {
            if (localVideoTrackRef.current !== null) {
              localVideoTrackRef.current.detach($(`#mainVideo`)[0]);
            }

            if (localAudioTrackRef.current !== null) {
              localAudioTrackRef.current.detach($(`#mainAudio`)[0]);
            }

            for (let i = 0; i < localTracks.length; i++) {
                localTracks[i].dispose();
            }
        } catch (error) {
          console.alert(1290, error)
        }

        if (room.current !== null) {
          await room.current.leave();
          room.current = null;
        }

        if (connection.current !== null) {
          connection.current.removeEventListener(window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, onConnectionSuccess);
          connection.current.removeEventListener(window.JitsiMeetJS.events.connection.CONNECTION_FAILED, onConnectionFailed);
          connection.current.removeEventListener(window.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, onConnectionDisconnected);
          await connection.current.disconnect();
          connection.current = null;
        }
      } catch (error) {
        console.error(1222,error)
      }

    }

    const onLocalTracks = (tracks) => {
      tracks.forEach(async(localTrack, index) => {
        console.alert(1221, "localTrack ", localTrack.getType(),localTrack.deviceId)
            if (localTrack.getType() === Str.STR_VIDEO) {
                localTrack.attach($(`#mainVideo`)[0]);
                // don't track desktop screen for localVideo at setting menu
                if(localTrack.videoType !=='desktop') {
                    localStorage.setItem(Storages.LOCAL_CAMERA_ID,localTrack.deviceId)
                    setIsShareScreenOn(false)
                    const videoTrack = localTrack.getTrack();
                    localTrack.attach($("#localVideo")[0]);
                    if(videoConstraintsRef.current) {
                      let config=videoConstraintsRef.current
                      config.deviceId={exact:localTrack.deviceId}
                      videoConstraintsRef.current=config
                      await videoTrack.applyConstraints(config)
                    }
                }
                setLocalVideoTrack(localTrack);
            } else if (localTrack.getType() === Str.STR_AUDIO) {
                localTrack.attach($(`#mainAudio`)[0]);
                localStorage.setItem(Storages.LOCAL_MIC_ID,localTrack.deviceId)
                setLocalAudioTrack(localTrack);
                // localTrack.mute();
            }
            localTracks.push(localTrack);
            loadTrack(localTrack)
        });
    }
    function loadTrack(localTrack) {
      try{
        if (joinState || isJoined) {
          let tempTrack = null;
          if(room.current){
            room.current.getLocalTracks().forEach((track) => {
                if (track.getType() === localTrack.getType()) {
                    tempTrack = track;
                }
            });
            if (tempTrack) {
                room.current.replaceTrack(tempTrack, localTrack);
            }
            else {
                room.current.addTrack(localTrack);
            }
          }
          localTracks.push(localTrack);
        } else {
          setTimeout(() => loadTrack(localTrack), 1000)
        }              
      } catch (err) {
        console.error(1455, err)
        toast.error(window.i18n.getString("mediaTrackError"), {autoClose: 5000});
      }
    }

    const onRemoteTrack = (track) => {
        if (track.isLocal()) {
          return;
        }

        isSignalAccept.current = true;
        //---save all tracks incoming----
        let isFind = false;
        let listData;
        const participant = track.getParticipantId();
        const type = track.getType();

        console.alert(1230, "onTrackAdded after isLocal, id", participant, "type", type)

        listData = [...listRemoteUserData];
        listData.forEach((remoteUser, index) => {
            if (remoteUser.id === participant) {
                if (type === Str.STR_VIDEO) {
                    remoteUser.videotrack = track;
                    listData[index] = remoteUser;

                    if (remoteUser.user.getDisplayName() === receptionName.current && receptionName.current !== "") {
                        setUserVideoTrack(remoteUser.videotrack);
                        receptionId.current = remoteUser.videotrack.getParticipantId();
                        if (isHold === false && $(`#userVideo`)[0] !== undefined) {
                            remoteUser.videotrack.attach($(`#userVideo`)[0]);
                            stageTrack(remoteUser.videotrack)
                        }
                    }
                } else if (type === Str.STR_AUDIO) {
                    remoteUser.audiotrack = track;
                    listData[index] = remoteUser;

                    if (remoteUser.user.getDisplayName() === receptionName.current && receptionName.current !== "") {
                        setUserAudioTrack(remoteUser.audiotrack);
                        if (isHold === false && $(`#userAudio`)[0] !== undefined) {
                            remoteUser.audiotrack.attach($(`#userAudio`)[0]);
                        }
                    }
                }
                isFind = true;
            }
        });
        if (isFind === true) {
            listRemoteUserData = [...listData];
            setRemoteUserData(listData)
            return;
        }

        let user_val = { id: participant, user: null, videotrack: null, audiotrack: null };
        listRemouteUsers.forEach((cell) => {
            if (cell.id === participant) {
                user_val.user = cell.user;
            }
        });
        // Sometimes some tracks have an endpoint that is not in our listRemoteUsers array. We just ignore them
        if(!user_val.user) return

        if (type === Str.STR_VIDEO) {
            user_val.videotrack = track;
            if (user_val.user.getDisplayName() === receptionName.current && receptionName.current !== "") {
                setUserVideoTrack(user_val.videotrack);
                receptionId.current = user_val.videotrack.getParticipantId();
                if (isHold === false && $(`#userVideo`)[0] !== undefined) {
                    user_val.videotrack.attach($(`#userVideo`)[0]);
                }
            }
        } else if (type === Str.STR_AUDIO) {
            user_val.audiotrack = track;
            if (user_val.user.getDisplayName() === receptionName.current && receptionName.current !== "") {
                setUserAudioTrack(user_val.audiotrack);
                if (isHold === false && $(`#userAudio`)[0] !== undefined) {
                    user_val.audiotrack.attach($(`#userAudio`)[0]);
                }
            }
        }

        listData.push(user_val);
        listRemoteUserData = [...listData];
        setRemoteUserData(listData)
        return;
    }

    const onRemoveTrack = (track) => {
        const participant = track.getParticipantId();
        const type = track.getType();

        console.alert(1231, "onRemoveTrack, id", participant, "type", type, 'receptionId: ',receptionId.current,'$(`#userAudio`): ',$(`#userAudio`)[0] !== undefined)
        if (type === "video" && participant === receptionId.current && $(`#userVideo`)[0] !== undefined) {
            track.detach($(`#userVideo`)[0]);
        }
        
        if (type === "audio" && participant === receptionId.current && $(`#userAudio`)[0] !== undefined) {
            track.detach($(`#userAudio`)[0]);
        }
    }

    const onChangeName = (id, displayName) => {

    }

    function onConferenceJoined() {
        if (isLeave.current === true || window.location.hash === "#/login") {
            // unload();
            return;
        }

        isJoined = true;
        setJoinState(true);
        // localTracks.forEach((localTrack) => {
        //     room.current.addTrack(localTrack);
        //     room.current.setDisplayName(props.match.params.username);
        // });

        //update status as JOINED
        updateUserStatus(Constant.JOIN);
        stageTrack(null)
    }

    function updateUserStatus(status) {
        console.alert(1232, "updateUserStatus, status:", status)

        AdminService.updateLocationStatus({status})
            .then(
                response => {
                    if (response.data.code !== 200) {
                      console.alert(1285, response.data)
                      toast.error(window.i18n.getString("serverError"), {autoClose: 7 * 1000});
                    }
                },
                error => {
                  console.error(1284, error)
                    toast.error(window.i18n.getString("serverError"), {autoClose: 7 * 1000});
                }
            );
    }

    function onUserLeft(id, user) {
      console.alert(1235, "onUserLeft, id:", id, "user:", user.getDisplayName(), "receptionName", receptionName.current)

        let remove = -1;
        listRemouteUsers.forEach((cell, index) => {
            if (cell.id === id) {
                remove = index;
                if (cell.user.getDisplayName() === receptionName.current) {
                    callEnd('Failed');
                }
            }
        });

        if (remove !== -1) {
            listRemouteUsers.splice(remove, 1);
        }
    }

    function onConferenceLeft() {
        if (isLeave.current === false) {
            //   room.current.join();
            if(window.electron) {
              window.electron.reload();
            } else {
              window.location.replace("#/")
            }
        }
    }

    const handleParticipantPropertyChange = (participant, propertyName, oldValue, newValue) => {
      // console.alert(
      //   1236,
      //   "handleParticipantPropertyChange",
      //   participant.getId(),
      //   participant.getDisplayName(),
      //   propertyName,
      //   oldValue,
      //   newValue
      // );
        if (isSignalAccept.current === false || newValue === Constant.NONE_VALUE || propertyName !== props.match.params.username) {
            return;
        }

        if (Constant.CALL_ACCEPT_VALUE == newValue && receptionName.current != '')
            return;
   

        switch (newValue) {
            case Constant.CALL_END_VALUE:
                if (callStatus.current === true) {
                    callEnd();
                } else {
                  receptionName.current = "" 
                }
                break;
            case Constant.CALL_ACCEPT_VALUE:
                receptionId.current = participant.getId();
                receptionName.current = participant.getDisplayName();
                callStart();
                break;
            // case Constant.CALL_PAUSE_VALUE:
            //     callPause();
            //     break;
            // case Constant.CALL_PLAY_VALUE:
            //     callPlay();
            //     break;
            default:
                break;
        }
    }

    function callPlay() {
      console.alert(1240, "callPlay", receptionName.current)

      setHoldStatus(false);
      listRemoteUserData.forEach((remoteUser) => {
          if (remoteUser.user.getDisplayName() === receptionName.current) {
              setUserVideoTrack(remoteUser.videotrack);
              setUserAudioTrack(remoteUser.audiotrack);
              if (remoteUser.videotrack) {
                  receptionId.current = remoteUser.videotrack.getParticipantId();
                  remoteUser.videotrack.attach($(`#userVideo`)[0]);
              }
              if (remoteUser.audiotrack) {
                  remoteUser.audiotrack.attach($(`#userAudio`)[0]);
              }
          }
      });
      updateUserStatus(Constant.CALL);
      startCheckCallStateInterval()
    }

    function callPause() {
      console.alert(1241, "callPause", receptionName.current)

      setHoldStatus(true);
      updateUserStatus(Constant.HOLD);
      clearInterval(checkCallStateInterval)
    }

    async function callStart() {
      console.alert(1242, "callStart", receptionName.current)

        if (callStatus.current)
            return;

        AdminService.sendMessage({
            to: receptionName.current,
            event: "sendVolumeToReception",
            msg: {volume: localStorage.getItem('location_volume')}
        }).catch((err) => {
          console.error(1283, err)
        })


        if(window.electron && process.env.NODE_ENV !='development') {
          window.electron.unmuteSoundDevices();
          let volume=localStorage.getItem("volume")
          if(volume) window.electron.setVolume(volume)
        }
        props.biRef.inComingCallFromReception();
        setWait(false);
        setHoldStatus(false);
        //---should insert code here to verify if participant is really reception.--
        listRemoteUserData.forEach((remoteUser) => {
            if (remoteUser.user.getDisplayName() === receptionName.current) {
                receptionId.current = remoteUser.user.getId();
                setUserVideoTrack(remoteUser.videotrack);
                setUserAudioTrack(remoteUser.audiotrack);
                if (remoteUser.videotrack) {
                    remoteUser.videotrack.attach($(`#userVideo`)[0]);
                    stageTrack(remoteUser.videotrack)
                }
                if (remoteUser.audiotrack) {
                    remoteUser.audiotrack.attach($(`#userAudio`)[0]);
                }
                joinCall();
                setCallStatus(true);
                updateUserStatus(Constant.CALL);
            }
        });
        await unmuteMe()

        let unmuteVideoTrackFound = false, unmuteAudioTrackFound = false
        if(room.current){
            room.current.getLocalTracks().forEach(async (track) => {
                if(!track.isMuted()) {
                  if(track.getType() === "video") {
                    unmuteVideoTrackFound = true;
                  } else if (track.getType() === "audio") {
                    unmuteAudioTrackFound = true;
                  }
                }
            });
        }
        if(!unmuteVideoTrackFound || !unmuteAudioTrackFound) {
          const problem = !unmuteVideoTrackFound ? "video" : "audio"
          AdminService.sendMessage({
            to: receptionName.current,
            event: "toast",
            msg: {code: -87, text: `Location's ${problem} track has error (Either no device found or is muted by system).`}
          }).catch((err) => {
            console.error(1251, err)
          })
          window.confirmAsync.show(
            <h6 style={{fontSize: "1.35rem", marginBottom: "0px"}}>Error</h6>, 
            <span style={{fontSize: "1.15rem", marginBottom: "0px"}}>
              There is an issue with {problem} track. Please reload. 
              <br/>
              If this issue persists, please contact customer support.
            </span>, 
            [
              { value: 1, color: "primary", text: "Reload now", close: 1 }, 
              { value: 0, color: "default", text: "Cancel", close: 1 }
            ]
          ).then((value) => {
            if(value === 1) {
              if(window.electron) {
                window.electron.reload();
              } else {
                window.location.replace("#/")
              }
            }
          })
        }

        startCheckCallStateInterval()
    }
    async function unmuteMe() {
      try {
        if(room.current && typeof room.current.getLocalTracks == "function") {
          const tracks = room.current.getLocalTracks()
          for(let track of tracks){
              
            if (track.isMuted()) {
              await track.unmute();
            }
          }
        }
      } catch (error) {
        console.error(1983,error)
      }
    }
    // function socketCallStart() {
    //     if (callStatus.current)
    //         return;

    //     props.biRef.inComingCallFromReception();
    //     setWait(false);
    //     setHoldStatus(false);
    //     //---should insert code here to verify if participant is really reception.--
    //     remoteUserData.forEach((remoteUser) => {
    //         if (remoteUser.user.getDisplayName() === receptionName.current) {
    //             if (remoteUser.videotrack) {
    //                 receptionId.current = remoteUser.videotrack.getParticipantId();
    //                 remoteUser.videotrack.attach($(`#userVideo`)[0]);
    //                 setUserVideoTrack(remoteUser.videotrack);
    //             }
    //             if (remoteUser.audiotrack) {
    //                 remoteUser.audiotrack.attach($(`#userAudio`)[0]);
    //                 setUserAudioTrack(remoteUser.audiotrack);
    //             }
    //             joinCall();
    //             setCallStatus(true);
    //             updateUserStatus(Constant.CALL);
    //         }
    //     });
    //     unmuteMe()
    // }

    function setCallStatus(flag) {
        setCall(flag);
        callStatus.current = flag;
    }

    function joinCall() {
      console.alert(1245, "joinCall", receptionName.current)

      let data = {};
      data['receptionName'] = receptionName.current;
      AdminService.joinUserAndLocation(data).then(
          response => {
              if (response.data.code !== 200) {
                console.alert(1981, response.data)
                toast.error(1981, window.i18n.getString("serverError"), {autoClose: 7 * 1000});
              }
          },
          error => {
            console.error(1280, error)
            toast.error(window.i18n.getString("serverError"), {autoClose: 7 * 1000});
          }
      );

      setDisableCallEnd(false)
    }

    function callEnd(status) {
      console.alert(1246, "callEnd", receptionName.current,'status: ',status)

      clearInterval(checkCallStateInterval)
      
        listRemoteUserData.forEach((remoteUser) => {
            if (remoteUser.user.getDisplayName() === receptionName.current) {
                if (remoteUser.videotrack) {
                    remoteUser.videotrack.detach($(`#userVideo`)[0]);
                    setUserVideoTrack(null);
                }
                if (remoteUser.audiotrack) {
                    remoteUser.audiotrack.detach($(`#userAudio`)[0]);
                    setUserAudioTrack(null);
                }
            }
        });

        receptionId.current = null;
        if (userVideoTrack !== null) {
            userVideoTrack.detach($(`#userVideo`)[0]);
            setUserVideoTrack(null);
        }

        if (userAudioTrack !== null) {
            userAudioTrack.detach($(`#userAudio`)[0]);
            setUserAudioTrack(null);
        }

        stageTrack(null)
        props.callExit(callStatus.current, receptionName.current, callToken.current ,status);
        receptionName.current = "";
        setCallStatus(false);
        setWait(true);
        setBusy(false);
        setHoldStatus(false);
        setIsShareScreenOn(false) // stop share screen after end call
        if (room.current) {
            room.current.setLocalParticipantProperty(props.match.params.username, Constant.NONE_VALUE);
            room.current.setLocalParticipantProperty(props.match.params.username, Constant.CALL_END_VALUE);
            room.current.setLocalParticipantProperty(Constant.CALL_INCOMING_TOKEN, null);
        }
        updateCallTag(Str.STR_EMPUTY);
        callToken.current=null
        setDisableCallEnd(false)
    }

    const clickCallBtn = () => {
        //#FIX send request to api save requestEnd time
        callEnd('Missed');
    }

    function userLeaved(username) {
        let data = {username};
        AdminService.userLeave(data).catch((err) => {
          console.error(1279, err)
        });
    }

    function updateCallTag(strType,color) {
        //broadcast message with call type to all users.
        if (room.current !== null) {
            room.current.setLocalParticipantProperty(Str.STR_CALL_TYPE, Constant.NONE_VALUE);
            room.current.setLocalParticipantProperty(Str.STR_CALL_TYPE, strType);
            room.current.setLocalParticipantProperty(Str.STR_CALL_TYPE_COLOR, color);
        }

        let data = {};
        data['calltag'] = strType;
        data['token'] = callToken.current || null;
        AdminService.updateCallTag(data).catch((err) => {
          console.error(1278, err)
        });
    }

    /**
     * Function that turn on or off audio with flag on hold.
     */
    function setHoldStatus(flag) {
        setHold(flag);
        isHoldRef.current=flag
        localTracks.forEach((cell) => {
            if (cell.getType() === Str.STR_AUDIO) {
                if (flag === true) {
                    cell.mute().catch((err)=>{
                      console.error(1981,err)
                    });
                }
                else {
                    cell.unmute().catch((err)=>{
                      console.error(1982,err)
                    });
                }
                return;
            }
        });
    }
    const getDisplayNameReception=async (username)=>{
        try {
            var res=await AdminService.getDisplayNameReception({username})
            if(res.data.code==0) setDisplayNameReception(res.data.data)
            else setDisplayNameReception(username)
        } catch (error) {
            console.error("3265  ", error)
            setDisplayNameReception(username)
        }
    }

    const setVolume = async () => {
      if(window.electron) {
        let vol = localStorage.getItem("volume")
        if(vol) window.electron.setVolume(vol)
      }
    }
    
    const handleErrorTrack=(err,code,device)=>{
      const cat="ERROR_TRACK"
      console.error(code,cat,device,err)
      toast.error(window.i18n.getString("mediaTrackError"), {autoClose: 7 * 1000});
    }

  const requestCall=async (tag)=>{
    try {
      let result=await AdminService.requestCall({tag})
      if(result.data.code===0){
        return result.data.data.token
      }
      else return null
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const startResetLocalVideoConsTimeout = async () => {
    clearTimeout(resetLocalVideoConsTimeout.current)
    resetLocalVideoConsTimeout.current = setTimeout(() => {
      const defaultCons = localStorage.getItem("defaultLocalVideoCons");
      if(defaultCons) {
        setLocalVideoCons(JSON.parse(defaultCons))
      }
    }, (Constant.UPDATE_ACTIVITY_INTERVAL_DELAY * 2) + 1000);
  }

  const setLocalVideoCons = async ({maxFps, maxHeight, aspectRatio}) => {
    try{
      if(!maxFps && !maxHeight && !aspectRatio) {
        return null;
      }
      if(!localVideoTrackRef.current) {
        return null;
      }
      const videoTrack = localVideoTrackRef.current.getTrack();
      if(!videoTrack) {
        return null;
      }
      let constraints = videoTrack.getConstraints();
      if(!constraints) {
        return null;
      }
      if(constraints.frameRate && constraints.frameRate.max == maxFps 
        && constraints.height &&  constraints.height.max ==maxHeight ) return
      if(maxFps === "browser_default") {
        if(constraints.frameRate) {
          delete constraints.frameRate.max
        }
      } else if(maxFps !== undefined && maxFps !== null) {
        if(constraints.frameRate) {
          constraints.frameRate.max = maxFps;
        } else {
          constraints.frameRate = { max: maxFps }
        }
      }
      if(maxHeight === "browser_default") {
        if(constraints.height) {
          delete constraints.height.max
        }
      } else if(maxHeight !== undefined && maxHeight !== null) {
        if(constraints.height) {
          constraints.height.max = maxHeight;
        } else {
          constraints.height = { max: maxHeight }
        }
      }
      if(aspectRatio === "browser_default") {
        if(constraints.aspectRatio) {
          delete constraints.aspectRatio.exact
        }
      } else if(aspectRatio !== undefined && aspectRatio !== null) {
        if(constraints.aspectRatio) {
          constraints.aspectRatio.exact = aspectRatio
        } else {
          constraints.aspectRatio = {exact: aspectRatio}
        }
      }
      videoConstraintsRef.current=constraints
      currentLocalVideoCons.current = { maxFps, maxHeight, aspectRatio }
      window.JitsiMeetJS.createLocalTracks({
        devices: [Str.STR_VIDEO],
        cameraDeviceId: constraints.deviceId.exact
      })
    .then(onLocalTracks)
    .catch(error => {
      handleErrorTrack(error,1299,'camera')
    });
      return true;
    } catch (err) {
      console.error(1191, 'Error setting videoTrack constraints. Input:', maxFps, maxHeight, "err:", err);
    }
    
  }
  //Avoid requesting a call before connecting to the Jitsi room
  function checkRoom(){
    return room.current !== null  ? true : false;
  }

  //Avoid requesting a call before connecting to the Jitsi room
  function checkRoom(){
    return room.current !== null  ? true : false;
  }
  
  const startCheckCallStateInterval = () => {
    clearInterval(checkCallStateInterval)
    setConnectionIssue(false)

    // Setting an initial value for this variable so it wont be about last call or even null
    // By setting it to now, it will wait x seconds before showing network connection-
    // if location didn't answer inCallQuestion ever
    lastReceptionResToCallState.current = Date.now()

    checkCallStateInterval = setInterval(() => {
      if(!room.current) {
        console.error(1181, "!room.current in checkCallStateInterval")
        clearInterval(checkCallStateInterval)
        return
      }
      if(!receptionId.current) {
        console.error(1183, "!receptionId.current in checkCallStateInterval")
        clearInterval(checkCallStateInterval)
        return
      }
      if(!callStatus.current) {
        console.error(1185, "!callStateRef.current in checkCallStateInterval")
        clearInterval(checkCallStateInterval)
        return
      }

      room.current.sendEndpointMessage(receptionId.current, {type: "inCallQuestion"});

      if(lastReceptionResToCallState.current && lastReceptionResToCallState.current < (Date.now() - (7 * 1000))) {
        setConnectionIssue(true)
      } else {
        setConnectionIssue(false)
      }
    }, 2 * 1000);
  }

  const stageTrack = (track) => {
    //{"constraints":{"4942f96f-v0":{"maxHeight":720},"ffbe59bb-v0":{"maxHeight":180}},"defaultConstraints":{"maxHeight":0},"lastN":-1,"onStageSources":["4942f96f-v0"],"selectedSources":[]}
    // setTimeout(() => {
      let conf = {
        defaultConstraints: { maxHeight: 0 },
        // constraints: {},
        lastN: 0,
        // selectedSources: [],
        // onStageSources: [], // in new jitsi version, we use sourceName instead of endpointID
      }

      // make specific engaged video large
      if(track && track.getSourceName()){
         // in new jitsi version, we use sourceName instead of endpointID
        console.alert(1378, `stageTrack ${track.getSourceName()}`)
        conf.constraints = { [track.getSourceName()]: { maxHeight: -1, maxFrameRate: -1 } }
        conf.onStageSources = [track.getSourceName()]
        conf.lastN=1
      }

      room.current.setReceiverConstraints(conf)
    // }, 500);
  }

    return (
        <div className='patient-main'>
            <div className='patient-main-area'>
                <div className='patient-front-area'>
                    {
                        isWait ?
                            isBusy ?
                                <BusyArea />
                                :
                                <WaitArea />
                            :
                            isHold ?
                                <HoldArea />
                                :
                                null
                    }
                </div>
                <div className='patient-back-area'>
                    <div className='patient-video-area'>
                        <video className='patient-user-video' autoPlay='1' id='userVideo' playsInline />
                        {isHold ?
                            null
                            :
                            <audio autoPlay='1' id='userAudio' />
                        }
                        <div className='patient-black-div' style={{ visibility: isMuted === true && props.isShowCall ? 'visible' : 'collapse' }}></div>
                        {isCall ?
                          <label className='call_connnect_status'>
                            {connectTag}
                            {isVideoPaused && <VideocamOffIcon fontSize='large' color='warning'/>}
                          </label> : null
                        }
                    </div>
                </div>
                <div className='patient-main-video-area'>
                    <video className='patient-main-video' autoPlay='1' id='mainVideo' playsInline />
                    <audio autoPlay='1' muted='1' id='mainAudio' />
                </div>
            </div>
            <div className='patient-bottom-area'>
                {
                    isWait ?
                        <div className='patient-name-area'></div>
                        :
                        isCall ?
                            <div className='patient-name-area'>
                                <label className='patient-greating-lab'>{window.i18n.getString("hiim")}</label>&nbsp;
                                <label className='patient-name-lab'>{displayNameReception  || receptionName.current}</label>
                            </div>
                            :
                            null
                }
                {
                    (isWait || connectionIssue) ?
                        <div className='patient-call-div'>
                            {connectionIssue && <h4 className="text-warning d-inline mb-0 mr-3">Connection issues</h4>}
                            <Button className='call_btn drag_cancel' onClick={clickCallBtn}
                                variant="contained"
                                disabled={!joinState || disableCallEnd}
                                style={{
                                    width: '10vh',
                                    outline: 'red',
                                    borderRadius: 8,
                                    background: 'transparent',
                                    border: '2px solid white',
                                    padding: '0.3em',
                                }}>
                                <CallEndIcon className='patient-phone-icon' />
                            </Button>
                        </div>
                        :
                        isHold ?
                            null
                            :
                            < div className='volume_div drag_cancel'>
                                <AudioVolume setVolumeValue={setVolumeValue} volumeValue={volumeValue} receptionName={receptionName}/>
                            </div>
                }

            </div>
        </div>
    )
})

export default withRouter(Patient);
