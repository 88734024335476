import { createSlice } from '@reduxjs/toolkit';

export const connections = createSlice({
    name: "chert",
    initialState: {internet: true, server: true},
    reducers:{
      setInternet: (state, action) => {
        if(state.internet != action.payload) {
          state.internet = action.payload
          return state
        }
      },
      setServer: (state, action) => {
        if(state.server != action.payload) {
          state.server = action.payload
          return state
        }
      }
    }
})

export const { setInternet: setConnectionInternet, setServer: setConnectionServer } = connections.actions

export default connections.reducer