
import React, { useEffect , useState, memo, useContext} from 'react';
import { styled } from '@mui/material/styles';
import './modal-style.css';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { yellow, red, green } from '@mui/material/colors';
import { SocketContext } from '../../context/socket'
import ScannerIcon from '@mui/icons-material/Scanner';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import apiService from "../../services/api"
import { toast } from "react-toastify"
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import DraggableModal from './DraggableModal';

const PREFIX = 'ScanRequestModal';

const classes = {
  modalFormSelect: `${PREFIX}-modalFormSelect`
};

const FormControlStyled = styled(FormControl)(({
  theme: {palette,mode}
}) => ({
    '& label': {
      color: palette.color[mode],
    },
    '& label.Mui-disabled': {
      color: palette.lightGray[mode],
    },
    '& .MuiOutlinedInput-root': {
      color: palette.color[mode],
      '& fieldset': {
        borderColor: palette.lightGray[mode],
      },
      '&:hover fieldset': {
        borderColor: palette.primary[mode],
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.primary[mode],
      },
      '&.Mui-disabled fieldset': {
        borderColor: palette.lightGray[mode],
      },
      '&.Mui-disabled .MuiSelect-selectMenu': {
        color: palette.lightGray[mode],
      },
    },
  [`& .${classes.modalFormSelect}`]: {
    backgroundColor:palette.background[mode] + " !important",
    color:palette.color[mode] + " !important",
  }
}));
const ScanRequestModal = memo((props)=>{
    const {allLocations=[], locationEngaged, open, handleClose, reception}=props
    const [showModal, setShowModal] = useState(false)
    const [location, setLocation] = useState("");
    const [paperSource, setPaperSource] = useState("glass");
    const [disableInputs, setDisableInputs] = useState(false);
    const [scanLog, setScanLog] = useState("");
    const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
    const socket = useContext(SocketContext)
    const [disableScan,setDisableScan]=useState(false)
    const [batchID,setBatchID]=useState('')

    useEffect(() => {
      socket.on('msg',({event, msg})=>{
        if(event === "scan-res") {
          if(msg.code !== 0) {
            setDisableInputs(false);
            setDisableScan(false);
          } 
          setScanLog(msg.msg)
        }else if(event ==='scan-result-is-ready'){
          setDisableScan(false)
          setBatchID(msg.batchID)
          setScanLog("Last scan completed, Would you like to continue scanning?")
        }
      })

      return () => {
        socket.off("msg")
      }
    }, [])

    useEffect(() => {
      if(!open) {
        setShowModal(false)
      } else {
        setDisableInputs(false);
        setDisableScan(false);
        if(paperSource === "glass") {
          setScanLog(`Please ask the patient to put their document face down into the scanner. 
            Once done, you can proceed by pressing the scan button.`)
        } else if (paperSource === "duplex") {
          setScanLog(`Please ask the patient to insert their document into the scanner feeder. 
            Once done, you can proceed by pressing the scan button.`)
        }
        setBatchID('')
        setShowModal(true)
      }
    }, [open])

    useEffect(() => {
      setLocation(locationEngaged || "")
      setBatchID('')
    },[locationEngaged])

    useEffect(() => {
      if(paperSource === "glass") {
        setScanLog(`Please ask the patient to put their document face down into the scanner. 
          Once done, you can proceed by pressing the scan button.`)
      } else if (paperSource === "duplex") {
        setScanLog(`Please ask the patient to insert their document into the scanner feeder. 
          Once done, you can proceed by pressing the scan button.`)
      }
    }, [paperSource])

    function onLocationChanged(event) {
      setLocation(event.target.value);
    }

    function onPaperSourceChanged(event) {
      setPaperSource(event.target.value);
    }

    async function submit() {
      try{
        if(!location) {
          toast.error(`'location' is required.`, {autoClose: 5000})
          return;
        }

        setDisableInputs(true);
        setDisableScan(true);
        
        const scanRes = await apiService.sendScanRequest({
          location, 
          paperSource, 
          batchID, 
          status: "start"
        })
        if(scanRes.data.code !== 0) {
          setScanLog(<span style={{color: "red"}}>Error while sending scan request. message: {scanRes.data.msg}</span>)
          setDisableInputs(false);
          setDisableScan(false);
        } else {
          setScanLog("Scanning...")
        }
      } catch (err) {
        console.error(err)
        setScanLog(<span style={{color: "red"}}>Error while sending scan request. message: {err.message}</span>)
        setDisableInputs(false);
        setDisableScan(false);
      }
    }

    async function cancel(close) {
      try{
        await apiService.sendScanRequest({
          location, 
          status: "cancel"
        })
        if(close) {
          handleClose(true)
        } else {
          toast.success("Scanner has been reset.")
        }
      } catch (err) {
        console.error(err)
        setScanLog(<span style={{color: "red"}}>Error while sending cancel request. message: {err.message}</span>)
      }
    }

    return (
      <DraggableModal
        id='scan-request-modal'
        closeButton={false}
        header={<h5 className="mb-0">Scan Request</h5>}
        body={<>
          <FormControlStyled fullWidth variant="outlined" >
            <InputLabel id="location-label" disabled={disableInputs}>To Location</InputLabel>
            <Select
              label="To Location"
              labelId="location-label"
              onChange={onLocationChanged}
              value={location}
              disabled={disableInputs}
              className={classes.modalFormSelect}
            >
              <MenuItem value="">Please choose ...</MenuItem>
              {allLocations.map((item, index) => {
                const isSec = !item.isPrimary;
                if(isSec && !showSecondaryLocations) return;
                return (
                  <MenuItem key={index} value={item.username}>
                    {item.locationname}
                    {isSec && 
                      <sup style={{color: "#f3c200", fontWeight: "900"}} title='Secondary location'>&nbsp;2nd</sup>
                    }
                  </MenuItem>
                )
              })}
            </Select>
          </FormControlStyled>
          <FormControlStyled fullWidth variant="outlined"  className='mt-3 '>
            <InputLabel id="scan-type" disabled={disableInputs}>Paper Source</InputLabel>
            <Select
              label='Paper Source'
              labelId="scan-type"
              onChange={onPaperSourceChanged}
              value={paperSource}
              disabled={disableInputs}
              className={classes.modalFormSelect}
            >
              <MenuItem key="1" value="glass">Glass</MenuItem>
              <MenuItem key="2" value="duplex">Duplex</MenuItem>
            </Select>
          </FormControlStyled>
          <div style={{marginTop: "10px", marginBottom: "5px"}}>{scanLog}</div>
        </>}
        footer={<>
          {!disableInputs &&
            <Button
              className="button-yellow dialog_btn"
              variant="contained"
              color="secondary"
              startIcon={<SettingsBackupRestoreIcon />}
              onClick={() => cancel()}
              >
              Reset scanner
            </Button> 
          }
          <Button
            className="button-red dialog_btn"
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={() => cancel(true)}
            >
            Cancel
          </Button>
          <Button
            className="button-green dialog_btn"
            variant="contained"
            color="secondary"
            startIcon={<ScannerIcon />}
            onClick={submit}
            disabled={disableScan}
           >
            Scan
          </Button>
        </>}
        show={showModal}
      />
    );
})
export default ScanRequestModal