import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import './style.css';
import Storages from '../../constants/storages';
import { HiddenMenu } from '../../Components/Location/HiddenMenu'
import * as i18n from "../../services/i18n"
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

const Away = () => {
  const [logo, setLogo] = useState('');
  const [company, setCompany] = React.useState("");
  const [locationName, setLocaitonNmae] = React.useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    i18n.init();
    i18n.getLangsDicts().then((langsDictRes) => {
      if (langsDictRes.code !== 0) {
        toast.error(langsDictRes.msg, { autoClose: 7000 })
      }
      window.i18n.currentLangCode = window.i18n.defaultLang;
      setIsLoading(false)
    })

    setCompany(localStorage.getItem(Storages.LOCAL_COMPANY_NAME))
    setLocaitonNmae(localStorage.getItem(Storages.LOCAL_LOCATION_NAME))

    let logoName = localStorage.getItem("logo");
    if (!logoName || logoName === "null") {
      setLogo("")
    } else if (logoName.startsWith("http://") || logoName.startsWith("https://")) {
      setLogo(logoName)
    } else {
      setLogo(process.env.REACT_APP_UPLOAD_URL + logoName)
    }
  }, []);

  return (
    <>
      {isLoading ?
        <div className='root'>
          <HiddenMenu />
          <img className='top_tap' src={window.locationTopBanner} alt="top_tap" />
          <img className='bottom_tap' src={window.locationBottomBanner} alt="bottom_tap" />
          <ReactLoading className="m-auto" type={"spin"} color={"#0085d2"} />
        </div> :
        <div className='root'>
          <HiddenMenu />
          <img className='top_tap' src={window.locationTopBanner} alt="top_tap" />
          <img className='bottom_tap' src={window.locationBottomBanner} alt="bottom_tap" />
          <div className='d-flex flex-column align-items-center justify-content-start w-100 h-100'>
            <div className='d-flex align-items-end justify-content-center p-3'
              style={{ height: "30vh", width: "45vw" }}>
              {logo && <img style={{ maxHeight: "100%", maxWidth: "100%", zIndex: 1 }} src={logo} />}
            </div>
            <label className='welcom_lab'>
              {(!locationName || locationName === 'null' ? company : locationName) }
            </label>
            <label className='sub_lab' style={{marginTop: "8vh", whiteSpace: "pre-line", textAlign: "center"}}>
              {window.i18n.getString("locationAwayMsg")}
            </label>
          </div>
        </div>}
    </>
  )
}

export default withRouter(Away);