import { createSlice } from '@reduxjs/toolkit';


export const showSecondaryLocations=createSlice({
    name:"showSecondaryLocations",
    initialState:false,
    reducers:{
      setShowSecondaryLocations:(state, action)=>{
        state = action.payload
        return state
      }
    }
})

export const { setShowSecondaryLocations } = showSecondaryLocations.actions

export default showSecondaryLocations.reducer