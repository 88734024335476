import React from 'react';
import './callpage.css';
import ReceptionImage from '../../assets/images/reception_img.png';

const HoldArea = () => {

    return (
        <div className='patient-area'>
            <div id='frontend_div'></div>
            <img className='top_tap' id='call_top_tap' src={window.locationTopBanner} alt="top_tap" />
            <img className='bottom_tap' src={window.locationBottomBanner} alt="bottom_tap" />
            <img className='reception_tap' src={ReceptionImage} alt="reception_tap" />
            <div className='left_container' id='call_left_container'>
                <label className='call_main'>JUST MOMENT</label>
                <label className='sub_lab call_sub'>You have been placed on hold.</label>
                <label className='sub_lab call_sub'>A representative will be back</label>
                <label className='sub_lab call_sub'>with you shortly.</label>
            </div>
        </div>
    );
}

HoldArea.prototype = {

}

export default HoldArea;