import { configureStore } from '@reduxjs/toolkit';
import micReducer from './micSlice';
import cameraReducer from './cameraSlice';
import sleepLocationReducer from './sleepLocation';
import locationsReducer from './locations';
import apiCommitHash from './apiCommitHash';
import showSecondaryLocationsReducer from './showSecondaryLocations';
import messages from './messages';
import connections from './connections';
import tracksMuted from './tracksMuted';
import remoteStates from './remoteStates';
import speakerStates from './speaker';
import screens from './screen';
const store = configureStore({
	reducer: {
		mics: micReducer,
    	cameras: cameraReducer,
		isSleep:sleepLocationReducer,
		locations:locationsReducer,
		apiCommitHash:apiCommitHash,
		messages:messages,
		showSecondaryLocations: showSecondaryLocationsReducer,
		connections: connections,
		tracksMuted:tracksMuted,
		remoteStates: remoteStates,
		speakers:speakerStates,
		screens:screens,
	},
});

export default store
