import React from 'react';
import './callpage.css';
import ReceptionImage from '../../assets/images/reception_img.png';

const WaitArea = () => {

    return (
        <div className='patient-area' style={{position:"relative"}}>
            <div id='frontend_div'></div>
            <img className='top_tap' id='call_top_tap' src={window.locationTopBanner} alt="top_tap" />
            <img className='bottom_tap' src={window.locationBottomBanner} alt="bottom_tap" />
            <img className='reception_tap' src={ReceptionImage} alt="reception_tap" />
            <div className='left_container' id='call_left_container'>
                <label className='call_main'>{window.i18n.getString("thanks")}</label>
                <label className='sub_lab call_sub' style={{whiteSpace: "pre-line"}}>{window.i18n.getString("callConnectionWait")}</label>
                <div className='connecting_div'>
                    <div className='hello_div'>
                        <label className='sub_lab call_sub' id='welcom_sub_lab'>{window.i18n.getString("connecting")}</label>
                        <div className="spinner" id="busy_spinner">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

WaitArea.prototype = {

}

export default WaitArea;