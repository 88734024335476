import React from "react";
import Grid from "@mui/material/Grid";
import Storages from '../../constants/storages';
import CallTimeLabel from '../Controls/CallTimeLabel';
import './styleReception.css'
import { styled } from "@mui/material";

const Root = styled('div')(({theme: {palette,mode}}) => ({
  fontWeight: "650",
  fontSize: "1rem",
  letterSpacing: "0.00625em",
  color: palette.color[mode],
}))

export default function ReceptionInfo (){
  
    const name=localStorage.getItem(Storages.LOCAL_RECEPTION_NAME)

    return (
      <Root>
        <div className="text-capitalize">
          {name} &nbsp;| &nbsp;<CallTimeLabel fontSize={'1'} duringTime={0} play={true} />
        </div>
      </Root>
    );
}
 
