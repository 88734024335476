import React, { Component } from 'react';
import './colorbutton.css';

export default class ColorButton extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            Color: this.props.Color,
            Title: this.props.Title,
            Width: this.props.Width, 
            labelColor:this.props.labelColor,
            boxShadow:this.props.boxShadow,
        };
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ Color: nextProps.Color,
            Title:nextProps.Title,
            Width: nextProps.Width, 
            labelColor:nextProps.labelColor,
            boxShadow:nextProps.boxShadow,
         });
    }

    render() {
        var styleSheet = {
            backgroundColor: this.state.Color,
            width: this.state.Width,
            boxShadow:this.state.boxShadow
        }
        return (
            <div className={'color_btn m-2 '+(this.props.shape || 'oval')} style={styleSheet} onClick={() => this.props.onClicked()}>
                <label className='button_lab text-capitalize' style={{color:this.state.labelColor, }}>{this.state.Title}</label>
            </div>
        );
    }
}