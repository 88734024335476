import React, { useRef, useContext, useEffect ,useState, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {Grid,ButtonBase, CircularProgress, Tooltip, styled, Button, colors} from "@mui/material";
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import ScannerOutlinedIcon from '@mui/icons-material/ScannerOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import PhonePausedOutlinedIcon from '@mui/icons-material/PhonePausedOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import SpeakerOutlinedIcon from '@mui/icons-material/SpeakerOutlined';
import {  selectMic} from '../../redux/micSlice';
import { selectCamera } from '../../redux/cameraSlice';
import { selectSpeaker } from '../../redux/speaker';
import Storages from '../../constants/storages';
import MenuListComposition from '../Controls/MenuListComposition';
import FilterOutlinedIcon from '@mui/icons-material/FilterOutlined';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import CameraIcon from '@mui/icons-material/Camera';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import { toast } from "react-toastify";
import AdminService from '../../services/api'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import './styleReception.css'

const ToolboxButton = styled(Button)(({
  theme: {palette, mode}
}) => ({
  marginInline: "4px",
  padding: "9px",
  backgroundColor: palette.color[mode],//"#4c5053",
  borderRadius: "24px",
  minWidth: "0px",
  '& svg': {
    fontSize: "1.7rem",
    color: palette.background[mode],
  },
  ':hover': {
    backgroundColor: palette.toolboxButtonHover[mode],
  },
}))

const Root = styled('div')(({
  theme: {palette, mode}
}) => ({
  '& .reception-end-call-button': {
    backgroundColor: palette.error[mode],
    color: palette.error.contrastText,
    ':hover': {
      backgroundColor: palette.error[mode] + "cc",
    },
  },

  '& .reception-start-call-button': {
    backgroundColor: palette.success[mode],
    color: palette.success.contrastText,
    ':hover': {
      backgroundColor: palette.success[mode] + "cc",
    },
  },

  '& .reception-hold-call': {
    backgroundColor: palette.warning[mode],
    color: palette.warning.contrastText,
    ':hover': {
      backgroundColor: palette.warning[mode] + "cc",
    },
  },

  '& .reception-release-call': {
    backgroundColor: palette.success[mode],
    color: palette.success.contrastText,
    ':hover': {
      backgroundColor: palette.success[mode] + "cc",
    },
  }
}))

const audioContext = new AudioContext();

export default function ToolBox ({callState, handleCall, locationEngaged, allLocations, handlePlay,
    playState,handleOpenModalPayReq,handleRequestForScan,handleModalPrintFile,
    handleLocalCamera, handleLocalMic, micro, camera,handleOpenGallery, startCardDetection,
    toggleShareScreen, captureLocationCameraImage, handleExitFullScreenEngaged,
    monitorCallStatus,handleOpenMessagingBoard, handleDeviceManagement, callLoading
}){

    const dispatch = useDispatch();
    const mics = useSelector((state) => state.mics);
    const cameras = useSelector((state) => state.cameras);
    const speakers=useSelector((state)=>state.speakers)
    const [showPayButton, setShowPayButton] = useState(true)
    const [audioInputDevicesMenu, setAudioInputDevicesMenu] = useState([]); // {selected, label,id}
    const [audioOutputDevicesMenu,setAudioOutputDevicesMenu]=useState([])
    const [showCardDetection, setShowCardDetection] = useState(true)
    const micNodes = useRef([]);
    const notAllowedAlertShown = useRef(false);

    useEffect(() => {
      window.requestAnimationFrame(checkAudioLevelForAllMicNodes)
    }, [])

    useEffect(() => {
      getAllMicsAnalyserNodes()
    }, [mics])

    useEffect(()=>{
        if(speakers[0]){
            let arr=speakers.map((s)=>({...s,isSpeaker:true}))
            setAudioOutputDevicesMenu(arr)
        }
    },[speakers])

    const getAllMicsAnalyserNodes = async () => {
      try{
        const tmpMiceNodes = [];
        for(let mic of mics) {  
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: {
              deviceId: {
                exact: mic.deviceId,
              },
            },
          });

          const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
          const analyserNode = audioContext.createAnalyser();
          mediaStreamAudioSourceNode.connect(analyserNode);
          
          const array = new Uint8Array(analyserNode.frequencyBinCount);

          tmpMiceNodes.push({analyserNode, pcmData: array, mic})
        }
        micNodes.current = tmpMiceNodes;
      } catch(err) {
        if(!notAllowedAlertShown.current) {
          console.error(2020,err)
          err.message && toast.error("Get microphone levels: " + err.message)
          notAllowedAlertShown.current = true
        }
      }
    }

    const checkAudioLevelForAllMicNodes = async () => {
      try{
        let tmpAudioDevicesMenu = [];
        for(let micNode of micNodes.current) { 
          micNode.analyserNode.getByteTimeDomainData(micNode.pcmData);
          let level = timeDomainDataToAudioLevel(micNode.pcmData)

          // let label = micNode.mic.label
          // if(label.length > 30) {
          //   label = label.substr(0, 30) + "..."
          // }
          tmpAudioDevicesMenu.push({id:micNode.mic.id, label: micNode.mic.label, selected: micNode.mic.selected, bar: level*100})
        }
        setAudioInputDevicesMenu(tmpAudioDevicesMenu)

        window.requestAnimationFrame(checkAudioLevelForAllMicNodes);
      } catch (err) {
        console.error(2022,err)
      }
    }

    const timeDomainDataToAudioLevel = (samples) => {
      let maxVolume = 0;
      const length = samples.length;
      for (let i = 0; i < length; i++) {
        if (maxVolume < samples[i]) {
            maxVolume = samples[i];
        }
      }
      return parseFloat(((maxVolume - 127) / 128).toFixed(3));
    }

    useEffect(() => {
      if (!locationEngaged) {
        setShowPayButton(false)
      }
    }, [locationEngaged])

    useEffect(() => {
      if(locationEngaged) {
        const engagedObj = allLocations.find((item) => item.username === locationEngaged);
        if (engagedObj && engagedObj.deviceId) {
          setShowPayButton(true)
        } else {
          setShowPayButton(false)
        }
        if(engagedObj?.cardDetectionEnabled) {
          setShowCardDetection(true)
        } else {
          setShowCardDetection(false)
        }
      }
    }, [locationEngaged, allLocations])

    const onClickCameraSelector = (index) => {
        // let cameraDeviceId=cameras[index].deviceId
        // localStorage.setItem(Storages.LOCAL_CAMERA_ID,cameraDeviceId)
        dispatch(selectCamera({ index }));
        let label=cameras[index]?.label
        AdminService.createActivityLog({
          tags:['reception', 'camera'],
          action: "update-device",
          subAction: "camera",
          target: "__ME__",
          details: "Reception set camera to " + label
        })
    }
    const onClickMicSelector = (index) => {
        // localStorage.setItem(Storages.LOCAL_MIC_ID,deviceId)
        dispatch(selectMic({ index }));
        let label=mics[index]?.label
        AdminService.createActivityLog({
          tags: ['reception', 'microphone'],
          action: "update-device",
          subAction: "microphone",
          target: "__ME__",
          details: `Reception set microphone to ${label}`
        })
    }
    const onClickSpeakerSelector=async(index)=>{
        try {
            let speakerDeviceId = speakers[index].deviceId;
            localStorage.setItem(Storages.LOCAL_SPEAKER_ID,speakerDeviceId)
            dispatch(selectSpeaker({ deviceId: speakerDeviceId }));
            if(speakerDeviceId){
                document.getElementById('locationAudio') && (await document.getElementById('locationAudio').setSinkId(speakerDeviceId))
                document.getElementById('mainRAudio') && (await document.getElementById('mainRAudio').setSinkId(speakerDeviceId))
                document.getElementById('alarmAudio') && (await document.getElementById('alarmAudio').setSinkId(speakerDeviceId)) 
            }
            let label=speakers[index]?.label
            AdminService.createActivityLog({
              tags: ['reception', 'speaker'],
              action: "update-device",
              subAction: "speaker",
              target: "__ME__",
              details: `Reception set speaker to ${label}`
            })
        } catch (error) {
            console.error("12364: ",error)
        }
    }

    return (
        <Root> 
            <Grid container direction='row-reverse'>
                {callState?
                  <>
                    <ToolboxButton className="reception-end-call-button" onClick={handleCall}>
                      <Tooltip title='End Call'>
                        <CallEndOutlinedIcon className='toolbox-icon' />
                      </Tooltip>
                    </ToolboxButton>
                    <ToolboxButton className={playState ? "reception-hold-call" : "reception-release-call"} 
                      style={{paddingLeft: "8px"}} onClick={handlePlay}>
                      <Tooltip title={playState?"Hold Call":"Release Call"}>
                          {playState
                            ? <PhonePausedOutlinedIcon className="toolbox-icon" />
                            : <PhoneInTalkOutlinedIcon className="toolbox-icon" />
                          }
                      </Tooltip>
                    </ToolboxButton>
                  </>
                  :
                  locationEngaged &&
                  <>
                    <ToolboxButton className="reception-start-call-button" onClick={handleCall} disabled={callLoading}>
                      <Tooltip title={callLoading?'Connecting...':'Call'}>
                        {callLoading ?
                        <CircularProgress size={25} className='toolbox-icon' />:
                        <CallOutlinedIcon className='toolbox-icon' /> }
                      </Tooltip>
                    </ToolboxButton>
                  </>
                }
                {
                  ((locationEngaged && (!callState || (callState && !playState))) || monitorCallStatus)
                  ? 
                    <ToolboxButton onClick={handleExitFullScreenEngaged}>
                      <Tooltip title='Exit Full Screen'>
                        <FullscreenExitOutlinedIcon className='toolbox-icon' />
                      </Tooltip> 
                    </ToolboxButton>
                  : ""
                }
                <ToolboxButton onClick={handleOpenGallery}>
                  <Tooltip title='Gallery'>
                    <FilterOutlinedIcon className='toolbox-icon' />
                  </Tooltip>
                </ToolboxButton>
                {showPayButton && callState &&
                  <ToolboxButton onClick={handleOpenModalPayReq}>
                    <Tooltip title='Payment'>
                      <PaymentOutlinedIcon className='toolbox-icon'/>
                    </Tooltip>
                  </ToolboxButton>
                }
                {locationEngaged && callState &&
                  <ToolboxButton onClick={handleModalPrintFile}>
                    <Tooltip title='Print'>
                      <PrintOutlinedIcon className='toolbox-icon'/>
                    </Tooltip>
                  </ToolboxButton>
                }
                {locationEngaged && 
                  <ToolboxButton onClick={handleRequestForScan}>
                    <Tooltip title='Scanner'>
                      <ScannerOutlinedIcon className='toolbox-icon'/>
                    </Tooltip>
                  </ToolboxButton>
                }
                { locationEngaged && 
                  <ToolboxButton onClick={handleOpenMessagingBoard}>
                    <Tooltip title='Messaging Board'>
                      <LibraryBooksIcon className='toolbox-icon'/>
                    </Tooltip>
                  </ToolboxButton>
                }
                {locationEngaged && 
                  <>
                    {showCardDetection && 
                      <ToolboxButton onClick={startCardDetection}>
                        <Tooltip title="Detect ID card in location's camera">
                          <PageviewOutlinedIcon className='toolbox-icon' />
                        </Tooltip>
                      </ToolboxButton>
                    }
                    <ToolboxButton onClick={captureLocationCameraImage}>
                      <Tooltip title="Take picture from location's camera">
                        <CameraIcon className='toolbox-icon' />
                      </Tooltip>
                    </ToolboxButton>
                    <ToolboxButton onClick={toggleShareScreen}>
                      <Tooltip title='Start / Stop location share screen'>
                        <ScreenShareOutlinedIcon className='toolbox-icon'/>
                      </Tooltip>
                    </ToolboxButton>
                    <ToolboxButton onClick={handleDeviceManagement}>
                      <Tooltip title={`${locationEngaged}'s device management and volume`}>
                        <GraphicEqIcon className='toolbox-icon' />
                      </Tooltip>
                    </ToolboxButton>
                  </>
                }
                <ToolboxButton>
                  <Tooltip title='Start / Stop camera'>
                    <ButtonBase className='toolbox-btn-devices mr-2' onClick={handleLocalCamera}>
                        {camera?<VideocamOutlinedIcon className='toolbox-icon'/>
                            :<VideocamOffOutlinedIcon className='toolbox-icon'/>
                        }
                    </ButtonBase>
                  </Tooltip>
                  <MenuListComposition onClickItemSelector={(index) => onClickCameraSelector(index)} menus={cameras} title="Select camera"/>
                </ToolboxButton>
                {/* <ButtonBase className='toolbox-option-devices'>
                    <ArrowDropUpOutlinedIcon/>
                </ButtonBase> */}
                <ToolboxButton>
                  <Tooltip title='Mute /Unmute microphone'>
                    <ButtonBase className='toolbox-btn-devices mr-2' onClick={handleLocalMic}>
                        {micro? <MicNoneOutlinedIcon className='toolbox-icon'/>
                        :<MicOffOutlinedIcon className='toolbox-icon' /> }
                    </ButtonBase>
                  </Tooltip>
                  <MenuListComposition onClickItemSelector={(index) => onClickMicSelector(index)} title="Select microphone / speaker"
                    onClickItemSpeaker={(index)=>onClickSpeakerSelector(index)} menus={[
                      {
                        disabled:true,
                        label:<label className='mb-0'> <MicNoneOutlinedIcon/>Microphones</label>
                      },
                      ...audioInputDevicesMenu,
                      {
                        disabled:true,
                        label:<label className='mb-0'><SpeakerOutlinedIcon/>Speakers</label>
                      },
                      ...audioOutputDevicesMenu
                    ]}/>
                </ToolboxButton>
            </Grid>
        </Root>
    );
}

