import React from 'react';
import { styled } from '@mui/material/styles';
import {Button,ClickAwayListener,Grow,Paper,Popper,MenuItem,MenuList,Tooltip, ButtonBase, Popover} from '@mui/material';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import '../Reception/styleReception.css'
const PREFIX = 'MenuListComposition';

const classes = {
  menuList: `${PREFIX}-menuList`,
  menuItem: `${PREFIX}-menuItem`,
  menuItemSelected: `${PREFIX}-menuItemSelected`
};

const Root = styled('span')(({theme: {palette, mode}}) => ({
  ".left-border": {
    borderLeft: "1px solid",
    borderColor: palette.background[mode],
  }
}))

const MyPopper = styled(Popper)(({theme: {palette, mode}}) => ({
  zIndex: 3,

  [`& .${classes.menuList}`]: {
    borderRadius:"5px",
    backgroundColor:"#1C1D1E",
    color:"white",
    'ul': {
      paddingTop: "0px",
      paddingBottom: "0px"
    }
  },

  [`& .${classes.menuItem}`]: {
    color:"white",
    maxWidth: "50vw",
    '&:hover':{
      backgroundColor:"#404040"
    },
  },

  [`& .${classes.menuItemSelected}`]: {
    color:"white",
    maxWidth: "50vw",
    backgroundColor:"#404040 !important",
  },
}));

const MenuListComposition = React.memo((props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, index, isSpeaker) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    if (index === undefined) {
      return;
    }
    if(isSpeaker)  props.onClickItemSpeaker(index)
    
    else props.onClickItemSelector(index);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const onClickAway = () => {
    if(open) {
      setOpen(false)
    }
  }

  return (
    <Root>
      <Tooltip title={props.title}>
        <ButtonBase className='toolbox-btn-devices left-border' ref={anchorRef} onClick={handleToggle} style={{marginRight: "-5px"}}>
          <ArrowDropUpIcon className='toolbox-icon' fontSize="large"/>
        </ButtonBase>
      </Tooltip>
      <MyPopper className='my-popover' open={open} anchorEl={anchorRef.current} anchorReference="anchorEl">
        <ClickAwayListener onClickAway={onClickAway}>
          <MenuList className={classes.menuList} autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
            {props.menus && props.menus.map((menu, index) => (
              <MenuItem
                className={((menu.selected)?classes.menuItemSelected:classes.menuItem) + " d-flex justify-content-between"}
                key={index}
                disabled={(menu.disabled)?true:false}
                selected={menu.selected}
                onClick={(event) => handleClose(event, menu.id, menu.isSpeaker)}
              >
                <span className='text-truncate' style={{textTransform: "none"}}>{menu.label}</span>
                {menu.bar !== undefined ? 
                  <div style={{width: "50px", minWidth: "50px", height: "10px", backgroundColor: "gray", 
                    marginLeft: "5px", borderRadius: "2px"}}>
                    <div style={{width: menu.bar + "%", height: "10px", 
                      backgroundColor: "green", borderRadius: "2px"}}>&nbsp;</div>
                  </div>
                : ""}
              </MenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      </MyPopper>
    </Root>
  );
})

export default MenuListComposition;