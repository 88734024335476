
import React, { memo } from 'react';
import Modal from 'react-bootstrap/Modal';
import './modal-style.css';

const PaymentRequestedModal = memo(({showModal, handleClose, amount, desc })=>{

    return (
        <Modal show={showModal} size={'md'} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Payment Requested</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='container'>
                    <div>
                      <label>{window.i18n.getString("amount")}: <br/><b>${amount}</b></label>
                    </div>
                    <div>
                      <label>{window.i18n.getString("description")}: <br/><b>{desc}</b></label>
                    </div>
                    <div>
                      <i>{window.i18n.getString("followCardTerminal")}</i>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
})
export default PaymentRequestedModal