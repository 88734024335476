
import React, { useEffect, useState, memo } from 'react';
import { styled } from '@mui/material/styles';
import './modal-style.css';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
  InputAdornment
} from "@mui/material";
import apiService from '../../services/api';
import Draggable from 'react-draggable';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify"


const DialogTitleStyled = styled(DialogTitle)(({
  theme: {palette,mode}
}) => ({
    cursor:"move",
    backgroundColor:palette.background[mode],
    color:palette.color[mode],
    '& .MuiTypography-h6':{
      color:palette.color[mode],
    }
}));
const PrintRequestModal = memo(({ allLocations, locationEngaged }) => {
  const [showModal, setShowModal] = useState(false)
  const [file, setFile] = useState("")
  const [pdfObjectUrl, setPdfObjectUrl] = useState("")
  const [location, setLocation] = useState("");
  const [disableInputs, setDisableInputs] = useState(false);
  const [saveFileAs, setSaveFileAs] = useState("");
  const [saveFile, setSaveFile] = useState(false);
  const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)

  useEffect(() => {
    if (window.electron) {
      window.electron.newPrintFile(async (event, data) => {
        setShowModal(true);
        setFile(data)
        let blobRes = await fetch(`data:application/pdf;base64,${data}`)
        let blob = await blobRes.blob()
        setPdfObjectUrl(URL.createObjectURL(blob) + '#toolbar=0&navpanes=0&scrollbar=0')
      });
    }
  }, [])

  useEffect(() => {
    setLocation(locationEngaged || "")
  }, [locationEngaged])

  function onLocationChanged(event) {
    setLocation(event.target.value);
  }

  function close() {
    setShowModal(false)
  }

  async function submit() {
    if (!location || !file) {
      toast.error(`'location' and 'file' are required.`, {autoClose: 5000})
      return;
    }
    setDisableInputs(true);
    try {
      if (window.electron && saveFile) {
        window.electron.saveFileCopy(saveFileAs);
      }
      const axiosRes = await apiService.sendMessage({ to: location, event: "print", msg: { file } });
      const printRes = axiosRes.data
      if (printRes.code == 0) {
        close();
        toast.success("Print request has been sent.");
      } else {
        toast.error(`Error while printing file: ${printRes.msg}`, {autoClose: 5000})
      }
    } catch (err) {
      console.error(err);
      toast.error(`Error while printing file: ${err.message}`, {autoClose: 5000})
    } finally {
      setDisableInputs(false)
    }
  }

  function onSaveFileChange(event) {
    setSaveFile(event.target.checked);
    let chosenLocation = allLocations.find(item => item.username === location);
    let locationText = "";
    if (chosenLocation) {
      locationText = ` for ${chosenLocation.locationname}`
    }
    setSaveFileAs(`New printed doc${locationText} on ${new Date().toLocaleString("en-ca", { hour12: false }).replace(/\D/ig, '')}`)
  }

  function onSaveFileAsChange(event) {
    setSaveFileAs(event.target.value)
  }

  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Dialog
        open={showModal}
        onClose={close}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        disableBackdropClick="false"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitleStyled disableTypography={true}>
          <h5>Send file for print</h5>
        </DialogTitleStyled>
        <DialogContent>
          <FormControl fullWidth style={{ height: "400px" }}>
            <object data={pdfObjectUrl} type="application/pdf" width="100%" height="100%"></object>
          </FormControl>
          <FormControl fullWidth variant="outlined" className="mt-3">
            <InputLabel id="location-label">To Location</InputLabel>
            <Select
              label="To Location"
              labelId="location-label"
              onChange={onLocationChanged}
              value={location}
              disabled={disableInputs}
            >
              <MenuItem value="">Please choose ...</MenuItem>
              {allLocations.map((item, index) => {
                const isSec = !item.isPrimary;
                if(isSec && !showSecondaryLocations) return;
                return (
                  <MenuItem key={index} value={item.username}>
                    {item.locationname}
                    {isSec && 
                      <sup style={{color: "#f3c200", fontWeight: "900"}} title='Secondary location'>&nbsp;2nd</sup>
                    }
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined" className="mt-1">
            <FormControlLabel
              control={
                <Checkbox
                  checked={saveFile}
                  onChange={onSaveFileChange}
                  name="saveFileChecked"
                  color="primary"
                />
              }
              label="Save file"
            />
          </FormControl>
          {saveFile && (
            <FormControl fullWidth variant="outlined">
              <InputLabel id="label" htmlFor="saveFileAs">Save File As</InputLabel>
              <OutlinedInput
                labelId="label"
                label="Save File As"
                id="saveFileAs"
                startAdornment={
                  <InputAdornment position="start">
                    C://VS_Documents//
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">.pdf</InputAdornment>
                }
                value={saveFileAs}
                onChange={onSaveFileAsChange}
                type={"text"}
              />
            </FormControl>
          )}
        </DialogContent>
        <DialogActions className="px-4 pb-4">
          <Button
            className="button-red dialog_btn"
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={close}
            disabled={disableInputs}
            >
            Cancel
          </Button>
          <Button
            className="button-yellow dialog_btn"
            variant="contained"
            color="secondary"
            startIcon={<PrintIcon />}
            onClick={submit}
            disabled={disableInputs}
           >
            Print
          </Button>
        </DialogActions>
      </Dialog>
    </Draggable>
  );
})
export default PrintRequestModal