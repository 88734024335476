import React, { Component } from 'react';
import './iconcolorbutton.css';


class BackButton extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {
      const { } = this.props;
      return (
          <div className={'back_btn'} onClick={() => this.props.onClicked()} >
              <label className='icon_lab'>{window.i18n.getString("backButton")}</label>
          </div>
      );
    }
}

export default BackButton