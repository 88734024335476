import React from 'react';
import './callpage.css';
import ReceptionImage from '../../assets/images/reception_img.png';

const BusyArea = () => {

    return (
        <div className='patient-area'>
            <div id='frontend_div'></div>
            <img className='top_tap' id='call_top_tap' src={window.locationTopBanner} alt="top_tap" />
            <img className='bottom_tap' src={window.locationBottomBanner} alt="bottom_tap" />
            <img className='reception_tap' src={ReceptionImage} alt="reception_tap" />
            <div className='left_container' id='call_left_container'>
                <label className='sub_lab call_sub' style={{whiteSpace: "pre-line"}}>{window.i18n.getString("busyArea")}</label>
            </div>
        </div>
    );
}

BusyArea.prototype = {

}

export default BusyArea;