
import React, { useEffect , useState, memo, useRef, useContext } from 'react';
import './modal-style.css';
import {
  Button,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  IconButton
} from "@mui/material";
import { toast } from 'react-toastify';
import Draggable from 'react-draggable';
import { SocketContext } from '../../context/socket';
import apiService from '../../services/api';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@mui/icons-material/Close';

let requestScanFrom = "";
let scanCanceled = false;
const ScanModal = memo(({showModal, handleOpen, handleClose, myUsername})=>{
    const socket = useContext(SocketContext);
    const [isLoading, setIsLoading] = useState(false)
    const [uploadingPercent, setUploadingPercent] = useState(0)
    const [isUploading,setIsUploading]=useState(false)
    const showModalRef = useRef()
    showModalRef.current = showModal;
    const fileNameListRef = useRef([])
    const scannedFileCountRef = useRef(0)
    const batchIDRef = useRef()

    const paperSourceRef = useRef()
    const scanButtonRef = useRef(null);
    const [modalText, setModalText] = useState("");

    useEffect(() => {
      if(showModal) {
        setIsLoading(false)
        setIsUploading(false)
      }
    }, [showModal])

    useEffect(() => {
      if(window.electron){        
        window.electron.scannerButtonClicked((event, data)=> {
          if(showModalRef.current) {
            scanButtonRef.current.click()
          } else {
            return toast.error(window.i18n.getString("scanNoScanRequest"), {autoClose: 7000})
          }
        })
      }

      socket.on("scanRequest", (data) => {
        if(data.status === "start") {
          requestScanFrom = data.from
          paperSourceRef.current = data.paperSource
          batchIDRef.current = data.batchID
          // setModalText(window.i18n.getString("wait"));
          handleOpen();
          handleScan();
        } else {
          handleCancel(true);
        }
      })

      return () => {
        socket.off('scanner')
      }
    }, [])

    const uploadFile = async () => {
      if(fileNameListRef.current.length > 0) {
        let fileName = fileNameListRef.current[0]
        window.electron.readScanFile(fileName).then(async (data) => {
          let progress = 100 - (fileNameListRef.current.length * 100 / scannedFileCountRef.current)
          setUploadingPercent(progress)
          if(data.code !== 0) {
            setModalText(data.msg);
            if(data.details && requestScanFrom) {
              try{
                await apiService.sendMessage({
                  to: requestScanFrom,
                  event: "scan-res",
                  msg: {code: data.code, msg: data.details}
                })
              } catch (err) {
                console.error(err)
              }
            }
            setTimeout(() => {
              uploadFile()
            }, 1000);
          } else {
            let uploadRes;
            try{
              const axiosRes = await apiService.uploadScanResult({
                file: data.data, 
                fromReception: requestScanFrom,
                batchID: batchIDRef.current,
                lastOneInABatch: fileNameListRef.current.length === 1,
              })
              uploadRes = axiosRes.data
            } catch (err) {
              console.error(err)
              uploadRes = {code: -1, msg: err.message}
            }
            if(uploadRes.code == 0) {
              window.electron.deleteScanFile(fileName)
              fileNameListRef.current = fileNameListRef.current.filter((item) => item !== fileName)
              setModalText(window.i18n.getString("scanUploading"));
              uploadFile()
            } else {
              setModalText(window.i18n.getString("uploadError"));
              if(uploadRes.msg && requestScanFrom) {
                apiService.sendMessage({
                  to: requestScanFrom,
                  event: "scan-res",
                  msg: {code: uploadRes.code, msg: uploadRes.msg}
                }).catch((err) => {
                  console.error(err);
                })
              }
              setTimeout(() => {
                uploadFile()
              }, 2000);
            }
          }
        })
      } else {
        toast.success(window.i18n.getString("scanSuccessful"), {autoClose: 7000});
        handleClose()
      }
    }

    const handleScan = () => {
      if(!requestScanFrom) {
        return toast.error(window.i18n.getString("scanNoScanRequest"), {autoClose: 7000})
      }

      scanCanceled = false;
      setIsLoading(true)
      setModalText(window.i18n.getString("wait"))
      if(!batchIDRef.current || batchIDRef.current =='')
        batchIDRef.current = myUsername + "-" + new Date().getTime()
      if(window.electron) {
        window.electron.scan(paperSourceRef.current, localStorage.getItem("current_default_scanner"))
        window.electron.scanRes(async (event, data)=>{
          if(scanCanceled) {
            return;
          }
          if(data.code === 0) {
            if(data.msg) {
              setModalText(data.msg)
            }
            if(data.details && requestScanFrom) {
              apiService.sendMessage({
                to: requestScanFrom,
                event: "scan-res",
                msg: {code: data.code, msg: data.details}
              }).catch((err) => {
                console.error(err);
              })
            }
            if(data.data && data.data.fileNameList) {
              fileNameListRef.current = data.data.fileNameList;
              scannedFileCountRef.current = data.data.fileNameList.length; 
              setIsLoading(false);
              setIsUploading(true);
              uploadFile();
            }
          } else if (data.code === 17 && requestScanFrom) {
            apiService.sendMessage({
              to: requestScanFrom,
              event: "scan-res",
              msg: {code: data.code, msg: "An error occurred and the message is shown to the location."}
            }).catch((err) => {
              console.error(err);
            })
          } else {
            setIsLoading(false)
            setIsUploading(false)
            if(data.msg) {
              setModalText(data.msg)
            }
            if(data.details && requestScanFrom) {
              apiService.sendMessage({
                to: requestScanFrom,
                event: "scan-res",
                msg: {code: data.code, msg: data.details}
              }).catch((err) => {
                console.error(err);
              })
            }
          }
        })
      }
    }

    const handleCancel = async (fromReception) => {
      try{
        scanCanceled = true;
        setIsLoading(false)
        setIsUploading(false)
        toast.warning("Scan canceled!")

        await window.electron.cancelScan()

        if(!fromReception && requestScanFrom) {
          apiService.sendMessage({
            to: requestScanFrom,
            event: "scan-res",
            msg: {code: 4, msg: "Scan request has been canceled by the location"},
          }).catch((err) => {
            console.error(err);
          })
        }

        handleClose()
      } catch (err) {
        console.error(err)
        toast.error("Error while canceling scan")
      }
    }

    return (
      <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
        <Modal
          show={showModal}
          onHide={handleClose}
          backdrop="static"
          centered
          size={"md"}
          backdropClassName='d-none'
        >
          <DialogTitle className='d-flex flex-row justify-content-between pb-0 pr-1 pt-1' disableTypography={true}>
            <h5 className='pt-2'>{window.i18n.getString("scanTitle")}</h5>
            {!isLoading && <IconButton aria-label="close" className='float-right' onClick={handleClose}>
              <CloseIcon />
            </IconButton>}
          </DialogTitle>
          <DialogContent className='mb-2 pt-0'>
            <FormControl fullWidth variant="outlined" className='mt-1'>
              <h5>{modalText}</h5>
            </FormControl>
          </DialogContent>
          {/* <DialogActions className='px-4 pb-4'>
            <Button variant="contained" onClick={handleCancel} >
              {window.i18n.getString("cancel")}
            </Button> 
            <Button ref={scanButtonRef} variant="contained" color='primary' onClick={handleScan} disabled={isLoading || isUploading}>
              {window.i18n.getString("scanButton")}
            </Button> 
          </DialogActions> */}
          {isLoading? <LinearProgress />:""}
          {isUploading?<LinearProgress variant="determinate" value={uploadingPercent} />:""}

        </Modal>
      </Draggable>
    )
})
export default ScanModal