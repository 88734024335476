
import React, { useEffect , useState, memo, useContext} from 'react';
import { styled } from '@mui/material/styles';
import './modal-style.css';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { yellow, red } from '@mui/material/colors';
import {SocketContext} from '../../context/socket'
import CloseIcon from '@mui/icons-material/Close';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import apiService from '../../services/api';
import { useSelector } from 'react-redux';
import {toast} from "react-toastify"
import DraggableModal from './DraggableModal';

const PREFIX = 'CardDetectionRequest';

const classes = {
  root: `${PREFIX}-root`,
  yellowButton: `${PREFIX}-yellowButton`,
  redButton: `${PREFIX}-redButton`,
  modalFormControl: `${PREFIX}-modalFormControl`,
  modalFormSelect: `${PREFIX}-modalFormSelect`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(FormControl)(({
  theme: {palette,mode}
}) => ({
    '& label': {
      color: palette.color[mode],
    },
    '& label.Mui-disabled': {
      color: palette.lightGray[mode],
    },
    '& .MuiOutlinedInput-root': {
      color: palette.color[mode],
      '& fieldset': {
        borderColor: palette.lightGray[mode],
      },
      '&:hover fieldset': {
        borderColor: palette.primary[mode],
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.primary[mode],
      },
      '&.Mui-disabled fieldset': {
        borderColor: palette.lightGray[mode],
      },
      '&.Mui-disabled .MuiSelect-selectMenu': {
        color: palette.lightGray[mode],
      },
    },
    
  [`& .${classes.modalFormSelect}`]: {
    backgroundColor:palette.background[mode] + " !important",
    color:palette.color[mode] + " !important",
  },
  [`& .${classes.redButton}`]:{
    color: palette.getContrastText(red[700]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: '#ff0000',
    },
  },
  [`& .${classes.yellowButton}`]:{
    color: palette.getContrastText(yellow[700]),
    backgroundColor: yellow[500],
    '&:hover': {
      backgroundColor: '#ffff00',
    },
  },
}))

const CardDetectionRequest = memo(({allLocations, locationEngaged, open, handleClose})=>{
    const [showModal, setShowModal] = useState(false)
    const [location, setLocation] = useState("");
    const [cardDetectionLog, setCardDetectionLog] = useState("");
    const [disableInputs, setDisableInputs] = useState(false);
    const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
    const socket = useContext(SocketContext)

    useEffect(() => {
      socket.on('msg',({event, msg})=>{
        if(event === "card-detection-res") {
          if(msg.msg !== "Hold still!") {
            setDisableInputs(false);
          }
          setCardDetectionLog(msg.msg)
        }
      })

      return () => {
        socket.off("msg")
      }
    }, [])

    useEffect(() => {
      setCardDetectionLog("")
      setShowModal(open)
      setDisableInputs(false);
    }, [open])

    useEffect(() => {
      if(locationEngaged && locationEngaged !== location) {
        setLocation(locationEngaged)
      }
    },[locationEngaged])
    
    function onLocationChanged(event) {
      setLocation(event.target.value);
    }

    async function submit() {
      try{
        if(!location) {
          toast.error(`'location' is required.`, {autoClose: 5000})
          return;
        }

        setDisableInputs(true);
        setCardDetectionLog("Please wait...")

        await apiService.sendMessage({
          to: location,
          event: "card-detection-on"
        })

        setCardDetectionLog("Card detection started. Waiting for location")
      } catch (err) {
        console.error(err)
        toast.error(err.message, {autoClose: 10000})
      }
    }

    async function handleCancel() {
      apiService.sendMessage({
        to: location,
        event: "card-detection-off"
      }).catch((err) => {
        console.error(err);
      })

      setDisableInputs(false);
      setCardDetectionLog("")
      handleClose()
    }

    return (
      <DraggableModal
        closeButton={true}
        onCloseButton={handleCancel}
        header={<h5 className="mb-0">Card Detection</h5>}
        body={<>
          <Root fullWidth variant="outlined">
            <InputLabel id="location-label" disabled={disableInputs}>To Location</InputLabel>
            <Select
              label="To Location"
              labelId="location-label"
              onChange={onLocationChanged}
              value={location}
              disabled={disableInputs}
              className={classes.modalFormSelect}
            >
              <MenuItem value="">Please choose ...</MenuItem>
              {allLocations.map((item, index) => {
                const isSec = !item.isPrimary;
                if(isSec && !showSecondaryLocations) return;
                return (
                  <MenuItem key={index} value={item.username}>
                    {item.locationname}
                    {isSec && 
                      <sup style={{color: "#f3c200", fontWeight: "900"}} title='Secondary location'>&nbsp;2nd</sup>
                    }
                  </MenuItem>
                )
              })}
            </Select>
          </Root>
          <div style={{marginTop: "10px", marginBottom: "5px"}}>{cardDetectionLog}</div>
        </>}
        footer={<>
          <Button
            className={classes.redButton+ " dialog_btn"}
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={handleCancel}>Cancel</Button>
          <Button
            className={classes.yellowButton+" dialog_btn"}
            variant="contained"
            color="secondary"
            disabled={disableInputs}
            startIcon={<PageviewOutlinedIcon />}
            onClick={submit}> Start</Button>
        </>}
        show={showModal}
      />
    );
})
export default CardDetectionRequest