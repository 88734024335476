import React from "react";
import { useEffect } from "react";
import UserColor from '../../constants/usercolor';
import { useState } from "react";
import Str from '../../constants/string';
import Constant from '../../constants/constant';
import './style.css';

export default function LocationCallTag ({callState,locations,locationID}){
    const [tag,setTag]=useState()
    const [bgColor,setBgColor]=useState()

    useEffect(()=>{
        var user= locations.find((loc)=>loc.id===locationID)
        if(!user) {
            setTag(null)
            setBgColor(null)
            return
        }
        if(!user.calltag) {
            setTag(null)
            setBgColor(null)
        }
        if(!callState && user.calltag !== Str.STR_EMPUTY && user.status !== Constant.HOLD){
            var tagColor, tagName;
            tagName=user.calltag
            if(user.calltagColor) tagColor=user.calltagColor
            if(!tagColor){
                switch (user.calltag) {
                    case Str.STR_NORMAL:
                        tagColor = UserColor.GREEN_COLOR;
                        break;
                    case Str.STR_NEW_PATIENT:
                        tagColor = UserColor.YELLOW_COLOR;
                        break;
                    case Str.STR_NO_APPOINTMENT:
                        tagColor = UserColor.RED_COLOR;
                        break;
                    case Str.STR_SELF_CHECK:
                        tagColor = UserColor.BLUE_COLOR;
                        break;
                    case Str.STR_INITIAL_EVAL:
                        tagColor = UserColor.YELLOW_COLOR;
                        break;
                    case Str.STR_CHECK_IN:
                        tagColor = UserColor.BLUE_COLOR;
                    case Str.KIOSK:
                        tagColor = UserColor.YELLOW_COLOR;
                        break;
                    default:
                        tagColor = UserColor.GREEN_COLOR;
                }
            }
            setBgColor(tagColor)
            setTag(tagName)
        }


    },[locationID,locations])

    useEffect(()=>{
        if(callState){
            setTag(null)
            setBgColor(null)
        }
    },[callState])

    return (
        <>
            {
                tag ?
                    <div className='calltag_div_doctor' style={{ backgroundColor: bgColor }}>
                        <label className='calltag_lab_doctor' style={{ color: tag === Str.STR_NORMAL ? 'black' : 'white' }}>{tag}</label>
                    </div>
                    :null
            }
        </>
    )
}
