import React from "react";
import { styled } from '@mui/material/styles';
import { useEffect } from "react";
import {Grid,Slider} from '@mui/material'
import VolumeUp from '@mui/icons-material/VolumeUp';
import { ThemeProvider,createTheme } from '@mui/material/styles';
import apiService from "../../services/api";

const Root = styled('div')({
    width: '80%',
    marginRight: '1.5em'
});

const muiTheme = createTheme({
    overrides: {
        MuiSlider: {
            thumb: {
                color: "white",
            },
            track: {
                color: 'white'
            },
            rail: {
                color: 'black'
            }
        }
    }
});

export default function AudioVolume(props) {

    const handleChange = (event, newValue) => {
        props.setVolumeValue(newValue);
        apiService.sendMessage({
            to: props.receptionName.current,
            event: "sendVolumeToReception",
            msg: {volume: newValue}
        }).catch((err) => {
          console.error(err);
        })
    };

    useEffect( () => {
        props.setVolumeValue(localStorage.getItem('location_volume') || 75);
    }, [])

    useEffect(() => {
        localStorage.setItem('location_volume', props.volumeValue);
    }, [props.volumeValue]);

    return (
        <Root>
            <Grid container spacing={2}>
                <Grid item>
                    <VolumeUp style={{ color: 'white' }} />
                </Grid>
                <Grid item xs>
                    <ThemeProvider theme={muiTheme}>
                        <Slider
                            value={props.volumeValue}
                            onChange={handleChange}
                            aria-labelledby="continuous-slider"
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        </Root>
    );
}
