import React, { Component } from 'react';
import './iconcolorbutton.css';

export default class IconColorButton extends Component {
    constructor(props) {
        super(props);

        this.state = { Color: this.props.Color, Icon: this.props.Icon, Title: this.props.Title };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
      let newState = {Color: nextProps.Color}
      if(this.state.Title != nextProps.Title) {
        newState.Title = nextProps.Title
      }
      this.setState(newState);
    }

    render() {
        return (
            <div className='icon_btn' style={{ backgroundColor: this.state.Color }} onClick={() => this.props.onClicked()}>
                <img className='icon_img' src={this.state.Icon} alt="icon_img" />
                <label className='icon_lab'>{this.state.Title}</label>
            </div>
        );
    }
}