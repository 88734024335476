import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { Button, FormControl,TextField,colors } from '@mui/material';
import apiService from '../../services/api.js';
import ArrowBack from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';

const Root = styled('div')(({
  theme: {palette, mode}
}) => ({
  "& .loginSuccessButton": {
    color: "#ffffff",
    backgroundColor: colors.green[400],
    '&:hover': {
      backgroundColor: colors.green[600],
    },
    padding: "10px 19px",
    borderRadius: "40px",
    outline: "0px",
    fontWeight: "bolder",
    '& span': {
      paddingTop: "1px",
      alignItems: "start",
    },
    width: "100%"
  },

  "& .backButton": {
    backgroundColor: colors.grey[400],
    '&:hover': {
      backgroundColor: colors.grey[500],
    },
    borderRadius: "40px",
    outline: "0px",
    fontWeight: "bolder",
    padding: "3px 12px 3px 10px",
    '& span': {
      paddingTop: "1px",
      alignItems: "start",
    },
  },

  "& .formGroup": {
    '& span.MuiFormControlLabel-label': {
      marginTop: "4px"
    }
  }
}));

const ResetPassword = ({hideResetPassword}) => {
    const [pass1, setPass1] = useState('');
    const [pass1Error, setPass1Error] = useState("");
    const [pass2, setPass2] = useState('');
    const [pass2Error, setPass2Error] = useState("");
    const [token, setToken] = useState('');
    const [disableButton, setDisableButton] = useState(false);

    useEffect(()=>{
      const tokenParam = localStorage.getItem("resetPassToken")
      if(!tokenParam) {
        toast.error("Invalid reset password link. Please try again.")
        hideResetPassword()
        return
      }
      setToken(tokenParam)
    }, [])

    const pass1Changed = (event) => {
      const val = event.target.value
      setPass1(val)
      if (val && val.length > 8) { 
        setPass1Error('');
      }
    }

    const pass2Changed = (event) => {
      const val = event.target.value
      setPass2(val)
      if (val && val.length > 8 && val === pass1) { 
        setPass2Error('');
      }
    }

    const handleClickReset = async () => {
      if(!pass1) {
        setPass1Error("This field is required")
      }
      if(!pass2) {
        setPass2Error("This field is required")
      }
      if(pass1.length < 8) {
        setPass1Error("New password should be at least 8 characters")
        return
      }
      if(pass1 !== pass2) {
        setPass2Error("This should be the same as the new password")
        return
      }

      const waitToast = toast.info('Please wait...', {autoClose: false})
      try{
        setDisableButton(true)
        const response = await apiService.resetPassword({pass1, pass2, token})
        if (response.data.code !== 0) {
          toast.error(response.data.msg);
        } else {
          toast.success(`Your password has been reset. Please login with your new password`, {autoClose: false});
          localStorage.removeItem("resetPassToken")
          hideResetPassword()
        }
      } catch (err) {
        console.error(err)
        toast.error("internal server error");
      } finally {
        setDisableButton(false)
        toast.dismiss(waitToast)
      }
    }

    return (
      <Root>
        <div className="w-100 d-flex justify-content-center">
          <Button
            className="backButton"
            startIcon={<ArrowBack />}
            onClick={hideResetPassword}
            title="Back to login"
            style={{ position: "absolute", left: "15px" }}
          >
            Login
          </Button>
          <h4>Reset password</h4>
        </div>
        <div className="w-100 d-flex mt-3">
          <h6>
            Please enter your new password
          </h6>
        </div>
        <div className="w-100 d-flex justify-content-center mt-3">
          <FormControl fullWidth variant="standard">
            <TextField
              error={!!pass1Error}
              helperText={pass1Error || ""}
              value={pass1}
              type='password'
              label="New password"
              onChange={pass1Changed}
              required
            />
          </FormControl>
        </div>
        <div className="w-100 d-flex justify-content-center mt-3">
          <FormControl fullWidth variant="standard">
            <TextField
              error={!!pass2Error}
              helperText={pass2Error || ""}
              value={pass2}
              type='password'
              onChange={pass2Changed}
              label="Repeat new password"
              required
            />
          </FormControl>
        </div>
        <div className="w-100 d-flex justify-content-center mt-3">
          <Button
            className="loginSuccessButton"
            startIcon={<SendIcon />}
            onClick={handleClickReset}
            disabled={disableButton}
          >
            Reset
          </Button>
        </div>
      </Root>
    );
};

export default ResetPassword;