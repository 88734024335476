import React, { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles';
import { Modal, Col, Row } from 'react-bootstrap';
import Storages from '../../constants/storages';
import SettingsIcon from '@mui/icons-material/Settings';
import ScannerIcon from '@mui/icons-material/Scanner';
import VideocamIcon from '@mui/icons-material/Videocam';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import DeleteOutlinedRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import AdfScannerRoundedIcon from '@mui/icons-material/AdfScannerRounded';
import CompareRoundedIcon from '@mui/icons-material/CompareRounded';
import CreditCardOffOutlinedIcon from '@mui/icons-material/CreditCardOffOutlined';
import DoneIcon from '@mui/icons-material/Done';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import LibraryBooksOutlined from '@mui/icons-material/LibraryBooksOutlined';
import apiServices from '../../services/api'
import runAsAdminPng from '../../assets/images/run-as-admin.png'
import ReactLoading from 'react-loading';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { toast } from 'react-toastify';
import { AlertModal } from './AlertModal';
import { BatchScanModal } from './BatchScanModal';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import SpeedIcon from '@mui/icons-material/Speed';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popper,
  ClickAwayListener,
  MenuList,
  Grow,
  Paper,
  FormControl
} from "@mui/material";
import AudioDevices from '../Controls/AudioDevices';
import CameraDevices from '../Controls/CameraDevices';
import tokenService from '../../services/tokenService';
import { SpeedTest } from './SpeedTest';
import Boards from '../Controls/Boards';

export const AdministrationModal = ({
  limitedAccess,
  showModal,
  handleClose,
  handleExit,
  handleClearData,
  handleReload,
}) => {
  const [index, setIndex] = useState(0)
  const [hasScanHandler, setHasScanHandler] = useState(false);
  const [workingOnScanHandler, setWorkingOnScanHandler] = useState(false);
  const [batchScanModalShow, setBatchScanModalShow] = useState(false);
  const [tfjsEnabled, setTfjsEnabled] = useState(false)
  const [tfjsModal, setTfjsModal] = useState({ show: false, title: "", content: "" })
  const [ontopStatus, setOntopStatus] = useState(false)
  const anchorRef = useRef()
  const [defaultScanner, setDefaultScanner] = useState(null);
  const [scannerLoading, setScannerLoading] = useState(true)
  const [scanners, setScanners] = useState([]);
  const [isLocation, setIsLocation] = useState(false)
  const [openMenuScanner, setOpenMenuScanner] = useState(false)
  const [locationIsAway, setLocationIsAway] = useState(false)
  const savingDefaultScannerToast = useRef();

  useEffect(() => {
    const handlerExists = localStorage.getItem("scan_handler_exists")
    setHasScanHandler(handlerExists == "true")
    const userRole = localStorage.getItem(Storages.LOCAL_ROLE)
    let token = tokenService.get();
    var isLoc = (userRole === "location" && token) ? true : false
    var ontop = localStorage.getItem(Storages.LOCAL_ONTOP_MODE)
    ontop = (ontop === 'false') ? false : true
    setOntopStatus(isLoc && ontop)
    const locationIsAway = localStorage.getItem("locationIsAway")
    if (locationIsAway) {
      setLocationIsAway(true)
    } else {
      setLocationIsAway(false)
    }
  }, [])

  useEffect(() => {
    setTfjsEnabled(!!localStorage.getItem("tfjs_is_enable"))
    const userRole = localStorage.getItem(Storages.LOCAL_ROLE)
    let token = tokenService.get();
    var isLoc = (userRole === "location" && token) ? true : false
    setIsLocation(isLoc)
  }, [showModal])

  useEffect(() => {
    if (showModal && index === 1) {
      getScanners();
      const currentDefaultScanner = localStorage.getItem("current_default_scanner");
      if (currentDefaultScanner) {
        try {
          setDefaultScanner(JSON.parse(currentDefaultScanner));
        } catch (err) {
          localStorage.removeItem("current_default_scanner");
          setDefaultScanner(null)
        }
      }
    }
    if (!showModal || index !== 2) {
      // cancel all animation frame
      var id = window.requestAnimationFrame(function () { });
      while (id--) {
        window.cancelAnimationFrame(id);
      }
      return
    }
  }, [showModal, index])

  const handleListItemClick = (ev, val) => {
    setIndex(val)
  }
  // Card Detection
  const disableCardDetection = async () => {
    try {
      localStorage.removeItem("tfjs_is_enable");
      await apiServices.updateCardDetection({ status: 0 })
      if (window.electron) {
        await window.electron.deleteTfjsModel()
      }
      toast.success("Disabled successfully!")
      setTfjsEnabled(false);
    } catch (err) {
      console.error(4586, err)
      setTfjsModal({
        show: true,
        title: "Disabling Card Detection",
        content: err.message
      })
    }
  }

  const enableCardDetection = async () => {
    try {
      if (!window.electron) return
      setTfjsModal({
        show: true,
        title: "Enabling Card Detection",
        content: <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Downloading Microsoft Visual C++
          <ReactLoading className='d-inline-block ml-2' type="bubbles" color="black" height={"10px"} width={"22px"} /><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Installing Microsoft Visual C++.<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Checking if this CPU supports card detection (AVX).<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Downloading Card detection models.
        </p>
      })

      let modelUrl = "";
      let vcRedistUrl = "";
      if (localStorage.getItem("upload_url")) {
        modelUrl = localStorage.getItem("upload_url") + "id-card-tfjs-saved-model.zip"
        vcRedistUrl = localStorage.getItem("upload_url") + "vc-redist-2015.zip"
      } else {
        modelUrl = process.env.REACT_APP_UPLOAD_URL + "id-card-tfjs-saved-model.zip"
        vcRedistUrl = process.env.REACT_APP_UPLOAD_URL + "vc-redist-2015.zip"
      }

      const downloadRes = await window.electron.downloadVCRedist(vcRedistUrl);
      if (downloadRes.code !== 0) {
        setTfjsModal({
          show: true,
          title: "Enabling Card Detection",
          content: <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Error: Downloading Microsoft Visual C++ failed. Code: {downloadRes.code}
          </p>
        })
        return;
      }
      setTfjsModal({
        show: true,
        title: "Enabling Card Detection",
        content: <p>
          <DoneIcon color='success' /> Downloading Microsoft Visual C++.<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Installing Microsoft Visual C++
          <ReactLoading className='d-inline-block ml-2' type="bubbles" color="black" height={"10px"} width={"22px"} /><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Checking if this CPU supports card detection (AVX).<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Downloading Card detection models.
        </p>
      })

      const installRes = await window.electron.installVCRedist();
      if (installRes.code !== 0) {
        setTfjsModal({
          show: true,
          title: "Enabling Card Detection",
          content: <p>
            <DoneIcon color='success' /> Downloading Microsoft Visual C++.<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Error: Installing Microsoft Visual C++ failed. Code: {installRes.code}
          </p>,
        })
        return;
      }
      setTfjsModal({
        show: true,
        title: "Enabling Card Detection",
        content: <p>
          <DoneIcon color='success' /> Downloading Microsoft Visual C++.<br />
          <DoneIcon color='success' /> Installing Microsoft Visual C++<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Checking if this CPU supports card detection (AVX)
          <ReactLoading className='d-inline-block ml-2' type="bubbles" color="black" height={"10px"} width={"22px"} /><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Downloading Card detection models.
        </p>
      })

      const ableTfjs = await window.electron.checkAbleTfjs();
      if (!ableTfjs) {
        setTfjsModal({
          show: true,
          title: "Enabling Card Detection",
          content: <p>
            <DoneIcon color='success' /> Downloading Microsoft Visual C++.<br />
            <DoneIcon color='success' /> Installing Microsoft Visual C++<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Error: This CPU does not support card detection (AVX).
          </p>
        })
        return;
      }

      setTfjsModal({
        show: true,
        title: "Enabling Card Detection",
        content: <p>
          <DoneIcon color='success' /> Downloading Microsoft Visual C++.<br />
          <DoneIcon color='success' /> Installing Microsoft Visual C++.<br />
          <DoneIcon color='success' /> Checking if this CPU supports card detection (AVX).<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Downloading Card detection models
          <ReactLoading className='d-inline-block ml-2' type="bubbles" color="black" height={"10px"} width={"22px"} />
        </p>
      })

      await window.electron.downloadTfjsModel(modelUrl)
      setTfjsModal({
        show: true,
        title: "Enabling Card Detection",
        content: <p>
          <DoneIcon color='success' /> Downloading Microsoft Visual C++.<br />
          <DoneIcon color='success' /> Installing Microsoft Visual C++.<br />
          <DoneIcon color='success' /> Checking if this CPU supports card detection (AVX).<br />
          <DoneIcon color='success' /> Downloading Card detection models.<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Finishing up
          <ReactLoading className='d-inline-block ml-2' type="bubbles" color="black" height={"10px"} width={"22px"} />
        </p>
      })

      localStorage.setItem("tfjs_is_enable", true);
      await apiServices.updateCardDetection({ status: 1 })
      setTfjsEnabled(true);
      setTfjsModal({
        show: true,
        title: "Enabling Card Detection",
        content: <p>
          <DoneIcon color='success' /> Downloading Microsoft Visual C++.<br />
          <DoneIcon color='success' /> Installing Microsoft Visual C++.<br />
          <DoneIcon color='success' /> Checking if this CPU supports card detection (AVX).<br />
          <DoneIcon color='success' /> Downloading Card detection models.<br />
          <DoneIcon color='success' /> Enabled successfully!
        </p>
      })
    } catch (err) {
      console.error(4581, err)
      setTfjsModal({
        show: true,
        title: "Enabling Card Detection",
        content: "Error: " + err.message
      })
    }
  }

  const onTfjsModalClose = () => {
    setTfjsModal({ ...tfjsModal, show: false })
  }

  const handleToggleOnTop = () => {
    setOntopStatus(!ontopStatus)
    localStorage.setItem(Storages.LOCAL_ONTOP_MODE, !ontopStatus)
    if (window.electron) window.electron.setAlwaysOnTop(!ontopStatus)
  }

  // Scanner 
  const resetScanner = async () => {
    try {
      if (window.electron) {
        await window.electron.cancelScan()
        toast.success("Scanner has been reset.")
      }

    } catch (err) {
      console.error(err)
      toast.error("Error while resetting scanner. message: " + err.message)
    }
  }

  function scanHandlerClick() {
    setWorkingOnScanHandler(true)
    if (!hasScanHandler) {
      if (window.electron) {
        window.electron.addScanHandler().then((addRes) => {
          if (addRes.code === 0 || addRes.code === 2) {
            localStorage.setItem("scan_handler_exists", true);
            setHasScanHandler(true);
            toast.success("Successfully added!", { autoClose: addRes.code === 2 ? 10000 : 3000 })
          } else {
            toast.error(addRes.code + " " + addRes.msg, { autoClose: 10000 })
          }
          setWorkingOnScanHandler(false)
        });
      }
    } else {
      if (window.electron) {
        window.electron.removeScanHandler().then((removeRes) => {
          if (removeRes.code === 0) {
            localStorage.setItem("scan_handler_exists", false);
            setHasScanHandler(false);
            toast.success("Successfully removed!")
          } else {
            toast.error(removeRes.code + " " + removeRes.msg, { autoClose: 10000 })
          }
          setWorkingOnScanHandler(false)
        });
      }
    }
  }

  const handleBatchScan = () => {
    localStorage.setItem("reload_lock", 1)
    setBatchScanModalShow(true)
  }
  const batchScanModalClose = () => {
    setBatchScanModalShow(false)
    localStorage.removeItem("reload_lock")
  }

  // Default Scanner
  const handleClickMenutItem = (event) => {
    setOpenMenuScanner(true)
  };

  const handleCloseMenu = () => {
    setOpenMenuScanner(false)
  };

  const handleDefaultScannerClick = (event, scanner) => {
    setOpenMenuScanner(false)
    savingDefaultScannerToast.current = toast.info(<div>Getting scanner information. please wait...</div>, { autoClose: false });
    if (window.electron) {
      window.electron.setDefaultScanner(scanner).then((data) => {
        toast.dismiss(savingDefaultScannerToast.current);
        if (data.code !== 0) {
          toast.error(data.msg, { autoClose: 10 * 1000 })
        } else if (data.os === "windows") {
          setDefaultScanner(scanner);
          localStorage.setItem("current_default_scanner", JSON.stringify(scanner));
          toast.success("Success!")
        } else if (data.data.sources) {
          if (data.data.sources.length !== 3) {
            toast.warning(
              "This scanner does not support all sources. Supported sources are: " + data.data.sources.join(", "),
              { autoClose: 10 * 1000 }
            )
          }
          scanner.sources = data.data.sources
          setDefaultScanner(scanner);
          localStorage.setItem("current_default_scanner", JSON.stringify(scanner));
          toast.success("Success!")
          // handleClose();
        } else {
          console.error("7781", "Unknown error when selecting scanner. data:", data)
          toast.error("Unknown error when selecting scanner. No data", { autoClose: 10 * 1000 })
        }
      })
    }
  };

  function getScanners() {
    if (window.electron) {
      setScanners([])
      setScannerLoading(true)
      window.electron.listScanners().then((data) => {
        setScannerLoading(false)
        if (data.code === 0) {
          setScanners(data.data.scanners)
        } else {
          toast.error(data.msg, { autoClose: 10 * 1000 })
        }
      })
    }
  }

  const handleToggleIsAway = async () => {
    try{
      toast.info("Please wait...")
      const newIsAway = locationIsAway ? 0 : 1
      const setAwayRes = await apiServices.locationIsAway({ value: newIsAway });
      if(setAwayRes.data.code === 0) {
        if(newIsAway) {
          localStorage.setItem("locationIsAway", true)
          setLocationIsAway(true)
          window.location.replace("#/location/away")
        } else {
          localStorage.setItem("locationIsAway", false)
          setLocationIsAway(false)
          window.location.replace("#/")
        }
      } else {
        toast.error("Error while setting away screen: " + setAwayRes.data.msg, { autoClose: 10 * 1000 }) 
      }
    } catch (err) {
      console.error("7781", err)
      toast.error("Unknown error while setting away screen", { autoClose: 10 * 1000 })
    }
  }

  return (
    <>
      <Modal show={showModal} size='lg' onHide={handleClose} backdrop="static" keyboard={false}
        aria-labelledby="contained-modal-title-vcenter" centered >
        <Modal.Header closeButton>
          <Modal.Title>Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid" style={{ minHeight: "50vh", maxHeight: "80vh" }}>
          {limitedAccess ?
            <ListItem button key={'Batch Scan'} onClick={handleBatchScan}>
              <ListItemIcon>
                <CompareRoundedIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <label>Batch Scan</label>
                } />
            </ListItem>
            :
            <Row>
              <Col xs={4} md={4} style={{ borderRight: "1px solid black" }}>
                <ListItem button key={'General'} selected={index === 0} onClick={(event) => handleListItemClick(event, 0)}>
                  <ListItemIcon> <SettingsIcon  /> </ListItemIcon>
                  <ListItemText primary={
                    <label className="mb-0 " >General</label>
                  } />
                </ListItem>
                {isLocation &&
                  <>
                    <ListItem button key={'Scanner'} selected={index === 1} onClick={(event) => handleListItemClick(event, 1)}>
                      <ListItemIcon> <ScannerIcon  /> </ListItemIcon>
                      <ListItemText primary={
                        <label className="mb-0 ">Scanner</label>
                      } />
                    </ListItem>
                    <ListItem button key={'Audio'} selected={index === 2} onClick={(event) => handleListItemClick(event, 2)}>
                      <ListItemIcon> <VolumeDownIcon  /> </ListItemIcon>
                      <ListItemText primary={
                        <label className="mb-0 ">Audio</label>
                      } />
                    </ListItem>
                    <ListItem button key={'Video'} selected={index === 3} onClick={(event) => handleListItemClick(event, 3)}>
                      <ListItemIcon> <VideocamIcon  /> </ListItemIcon>
                      <ListItemText primary={
                        <span className="mb-0 "> Video</span>
                      } />
                    </ListItem>
                    <ListItem button key={'Boards'} selected={index === 4} onClick={(event) => handleListItemClick(event, 4)}>
                      <ListItemIcon> <LibraryBooksOutlined  /> </ListItemIcon>
                      <ListItemText primary={
                        <label className="mb-0 ">Boards</label>
                      } />
                    </ListItem>
                  </>
                }
                <ListItem button key={'Speedtest'} selected={index === 5} onClick={(event) => handleListItemClick(event, 5)}>
                  <ListItemIcon> <SpeedIcon  /> </ListItemIcon>
                  <ListItemText primary={
                    <span className="mb-0 "> Speed test</span>
                  } />
                </ListItem>
              </Col>
              <Col xs={8} md={8} >
                {index === 0 ?
                  <div className='d-flex flex-column'>
                    {isLocation &&
                      <>
                        {tfjsEnabled ?
                          <ListItem button key={'Card-Detection'} onClick={disableCardDetection}>
                            <ListItemIcon > <CreditCardOffOutlinedIcon/> </ListItemIcon>
                            <ListItemText primary={
                              <span>
                                Disable ID Card Detection
                              </span>
                            }>
                            </ListItemText>
                          </ListItem>
                          :
                          <ListItem button key={'Card-Detection'} onClick={enableCardDetection}>
                            <ListItemIcon >
                              <CreditCardOutlinedIcon/>
                              <img src={runAsAdminPng} className='administrator-icon' />
                            </ListItemIcon>
                            <ListItemText primary={
                              <span>Enable ID Card Detection</span>
                            }>
                            </ListItemText>
                          </ListItem>
                        }
                        <ListItem button key={'On-Top'} onClick={handleToggleOnTop}>
                          <ListItemIcon > <DesktopWindowsOutlinedIcon  /> </ListItemIcon>
                          <ListItemText primary={
                            <span >
                              {(ontopStatus) ? " Disable Always on Top" : " Enable Always on Top"}
                            </span>
                          }>
                          </ListItemText>
                        </ListItem>
                        <ListItem button key={'Is-Away'} onClick={handleToggleIsAway}>
                          <ListItemIcon > <PauseCircleOutlineIcon  /> </ListItemIcon>
                          <ListItemText primary={
                            <span >
                              {(locationIsAway) ? " Remove Away Screen" : " Show Away Screen"}
                            </span>
                          }>
                          </ListItemText>
                        </ListItem>
                      </>
                    }
                    <ListItem button key={'Reload'} onClick={handleReload}>
                      <ListItemIcon > <RotateLeftIcon  /> </ListItemIcon>
                      <ListItemText primary={
                        <span >Reload</span>
                      }>
                      </ListItemText>
                    </ListItem>

                    <ListItem button key={'Clear-Data'} onClick={handleClearData}>
                      <ListItemIcon > <DeleteOutlinedRoundedIcon  /> </ListItemIcon>
                      <ListItemText primary={<span >
                        Clear Data
                      </span>}>
                      </ListItemText>
                    </ListItem>

                    <ListItem button key={'Exit-App'} onClick={handleExit}>
                      <ListItemIcon > <LogoutIcon  /> </ListItemIcon>
                      <ListItemText primary={
                        <span >Exit App</span>
                      }>
                      </ListItemText>
                    </ListItem>
                  </div> : ""
                }
                {isLocation && index === 1 ?
                  <div className='d-flex flex-column'>
                    <ListItem button key={'scan-handler'} onClick={scanHandlerClick}>
                      <ListItemIcon >
                        <ScannerIcon  />
                        <img src={runAsAdminPng} className='administrator-icon' />
                      </ListItemIcon>
                      <ListItemText primary={
                        <span >
                          {workingOnScanHandler ? "Please wait..." : hasScanHandler ? "Remove scan handler" : "Add scan handler"}
                        </span>
                      } />
                    </ListItem>
                    <ListItem button key={'Batch-Scan'} onClick={handleBatchScan}>
                      <ListItemIcon>
                        <CompareRoundedIcon  />
                      </ListItemIcon>
                      <ListItemText primary={
                        <span >
                          Batch Scan
                        </span>
                      } />
                    </ListItem>
                    <ListItem button key={'Reset-Scanner'} onClick={resetScanner}>
                      <ListItemIcon >
                        <SettingsBackupRestoreIcon  />
                      </ListItemIcon>
                      <ListItemText primary={
                        <span >
                          Reset Scanner
                        </span>
                      } />
                    </ListItem>
                    <ListItem
                      ref={anchorRef}
                      button
                      aria-haspopup="true"
                      aria-controls="default-scanner"
                      onClick={handleClickMenutItem}
                      className='py-0'
                    >
                      <ListItemIcon>
                        <AdfScannerRoundedIcon  />
                      </ListItemIcon>
                      <ListItemText primary={
                        scannerLoading ?
                          <span >
                            Searching for scanners. Please wait...
                          </span> :
                          (scanners.length === 0 ?
                            <span >
                              No scanner found
                            </span> :
                            <span >
                              {defaultScanner ? defaultScanner.name : "No default scanner"}
                            </span>)
                      }
                        secondary={"Default Scanner"}
                      />
                    </ListItem>
                    <Popper open={openMenuScanner} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="bottom-start" style={{ zIndex: 1 }}>
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                          }}
                        >
                          <Paper >
                            <ClickAwayListener onClickAway={handleCloseMenu}>
                              <MenuList autoFocusItem={openMenuScanner} id="default-scanner">
                                {scanners.map((item, index) =>
                                  <MenuItem
                                    onClick={(event) => handleDefaultScannerClick(event, item)}
                                    selected={defaultScanner && item.id === defaultScanner.id}
                                    key={index}
                                    value={item.id}>
                                    ({item.type}) {item.name} {item.type === "escl" ? <i>&nbsp;(Recommended)</i> : ""}
                                  </MenuItem>
                                )}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div> : ""
                }
                {isLocation && index === 2 ?
                  <AudioDevices show={index === 2} audiosID={["userAudio"]} /> : ""
                }
                {isLocation && index === 3 ?
                  <>
                    <CameraDevices show={index === 3} />
                  </> : ""
                }
                {isLocation && index === 4 ? <Boards show={true} /> : ""}
                {index === 5 ? 
                  <FormControl fullWidth variant="outlined" className='mt-1'>
                    <SpeedTest />
                  </FormControl>
                : ""}
              </Col>
            </Row>
          }
        </Modal.Body>
      </Modal>
      <AlertModal
        showModal={tfjsModal.show}
        handleClose={onTfjsModalClose}
        title={tfjsModal.title}
        content={tfjsModal.content}
      />
      <BatchScanModal showDialog={batchScanModalShow} handleClose={batchScanModalClose} />
    </>
  )
}