import React, { Component } from 'react';
import './style.css';
import Constant from '../../constants/constant';
import Str from '../../constants/string';
import UserColor from '../../constants/usercolor';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MessageIcon from '@mui/icons-material/Message';
import { ThemeToggleContext } from '../../context/theme';
import {ButtonBase,Grid,Tooltip} from "@mui/material";
import { useEffect,useState } from 'react';
import {useSelector } from 'react-redux';

export default ({user_name,user_status,location_name,handleOpenChat,display_Name})=>{

    const [status,setStatus]=useState()
    const [color,setColor]=useState()
    const unRead = useSelector((state) => state.messages.unRead[user_name])

    useEffect(()=>{
        var userStatusName, statusColor;
        switch (user_status) {
            case Constant.LOGOUT:
                userStatusName = Str.STR_OFF_LINE;
                statusColor = UserColor.BROWN_COLOR;
                break;
            case Constant.AWAY:
                userStatusName = Str.STR_AWAY;
                statusColor = UserColor.YELLOW_COLOR;
                break;
            case Constant.JOIN:
                userStatusName = Str.STR_ON_LINE;
                statusColor = UserColor.GREEN_COLOR;
                break;
            case Constant.CALL:
                userStatusName = Str.STR_ON_CALL;
                statusColor = UserColor.PINK_COLOR;
                break;
            default:
                userStatusName = Str.STR_OFF_LINE;
                statusColor = UserColor.BROWN_COLOR;
        }
        setStatus(userStatusName)
        setColor(statusColor)
    },[user_status])

    const handleOnClickChat=()=> {
       handleOpenChat(user_name,display_Name)
    }

    return (
        <div className='remote_reception_area'>
        <FiberManualRecordRoundedIcon style={{color}} fontSize='small'/>
        <label className='d-flex align-items-center reception_name text-truncate'>
            {display_Name || user_name} 
            <span style={{fontSize: "14px"}}>&nbsp;
            {location_name ? 
                <span>(<PhoneInTalkIcon fontSize='inherit'/>&nbsp;{location_name})</span>: 
                ""
            }
            </span>
        </label>
        <ThemeToggleContext.Consumer>{({mode})=>{
            return (
                <Grid item  className='remote-user-view-grid'>
                    <Tooltip title={display_Name || user_name}>
                    <ButtonBase className='btn-chat' onClick={handleOnClickChat}>
                        <MessageIcon style={{color:(mode=='light'?'rgba(0, 0, 0, 0.87)':'#fff')}} />
                    </ButtonBase>
                    </Tooltip>
                    <div className='remote-user-view-number-unread'>
                        <small>{(unRead && unRead>0)?unRead :''}</small>
                    </div>
                </Grid>

            )
        }}
        </ThemeToggleContext.Consumer>
    </div>
    );
}